import React from 'react';

import { Translations } from './en';

export const de: Translations = {
  aborted: 'Abgebrochen',
  accelerationDescription:
    'Der Beschleunigungsfaktor wird für die Berechnung der Fahrkurve verwendet',
  accelerationNote:
    'Diese Einstellung muss groß genug sein um die zu eingestellten Türgeschwindigkeiten tatsächlich auch zu erreichen. Falls dieser Wert zu klein eingestellt ist wird die Tür ihre Geschwindigkeiten nicht erreichen',
  accelerationTitle: 'Beschleunigung',
  acceptTerms: 'Ich akzeptiere die Nutzungsbedingungen',
  add: 'Hinzufügen',
  addBuilding: 'Gebäude hinzufügen',
  addLift: 'Aufzug hinzufügen',
  addressHint:
    'Geben Sie den Firmen- oder Gebäudenamen in die Suche ein, um Adress- und Bildvorschläge zu erhalten.',
  adjustCameraSettings:
    'Sie können auch die Kameraeinstellungen über das Dropdown-Menü anpassen.',
  admin: 'Administration',
  alreadyHasSubcontractorsError:
    'Als Subunternehmer können Sie keine weiteren Subunternehmer haben. Bitte entfernen Sie vorhandene Subunternehmer, bevor Sie diese Einladung annehmen.',
  all: 'Alle',
  allBuildings: 'Alle Gebäude',
  allBuildingsSubscription: 'Von allen Gebäuden',
  alphabetical: 'Alphabetisch',
  ambientTemperature: 'Gehäusetemperatur',
  assetNumber: 'Anlagennummer',
  assetNumberHint:
    'Bitte geben Sie die Anlagennummer ein, sofern Sie diese zur Hand haben.',
  auto: 'Auto',
  back: 'Zurück',
  backToDashboard: 'Zurück zum Dashboard',
  belt_tension: 'Riemenspannung',
  belt_tension_actions:
    'Die Riemenspannung muss laut Installationsanleitung eingestellt werden.',
  beltclamp: 'Riemenklemme',
  belt_tension_description: 'Die Riemenspannung ist zu hoch/gering.',
  betweenDoorCycle: 'Zwischen dem Türzyklus',
  bottom: 'unten',
  bottomToTop: 'Von unten nach oben',
  boxLinkedToOtherLift: (lift: string, building: string) =>
    `Die Box ist bereits mit ${lift} in ${building} verknüpft. Fortfahren?`,
  boxNotReady: 'Die ausgewählte Box ist nicht verfügbar.',
  boxOfflineHint:
    'Wenn Sie eine der unten aufgeführten Boxen aktualisieren möchten, verbinden Sie sie bitte zuerst mit dem Internet.',
  boxScannedNoIdFound: 'Box gescannt, aber keine ID gefunden.',
  boxSoftware: 'Software',
  boxSoftwareStatusError:
    'Der Status der Boxen konnte nicht abgerufen werden: Bitte überprüfen Sie die Internetverbindung der Box und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an Ihren Wittur-Ansprechpartner.',
  boxSoftwareTitle: 'Box-Software',
  boxSoftwareUpdateError:
    'Fehler beim Aktualisieren der Box-Software: Bitte überprüfen Sie die Internetverbindung der Box und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an Ihren Wittur-Ansprechpartner.',
  box_id: 'Box ID',
  brakeParDescription:
    'Durch Einstellen dieses Parameter wird die Verzögerung und das Kriechen zum Offen / Geschlossen Ende beeinflußt (Voreingestellt auf ‘5’).',
  brakeParNote:
    'Der mögliche Einstellbereich ist ‘0’..’9’, wobei das früheste Anbremsen mit Parameter ‘0’ erreicht wird und das späteste Verzögern mit Parameter ‘9’ erreicht wird.',
  brakeParTitle: 'Bremsparameter',
  buffer: 'Puffer',
  building: 'Gebäude',
  buildingHasNoElevator: 'Dieses Gebäude hat keine Aufzüge.',
  buildingName: 'Name',
  buildings: 'Gebäude',
  buildingsWithoutValidAddress: 'Gebäude ohne gültige Adresse',
  bushingClose: 'Tasterabdeckung schließen',
  bushingOpen: 'Tasterabdeckung öffnen',
  cablechain: 'Kabelkette',
  cableTensorSpring: 'Seilspannfeder',
  callFloorsInstruction: (
    <>
      Öffnen Sie die Tür und schicken Sie den Aufzug{' '}
      <b>in alle anderen Stockwerke</b>. Treten Sie aus dem Fahrkorb und drücken
      Sie "Weiter".
    </>
  ),
  cameraMode: 'Kameramodus',
  cancel: 'Abbrechen',
  car: 'Kabine',
  carDoor: 'Fahrstuhlkorbtür',
  carDoorCounter: 'Türzyklenzähler der bisherigen Türelektronik',
  carDoorModified: 'Änderungen an der Fahrkorbtür vorgenommen',
  cd_counter_rollers_installation: 'Gegenrollen',
  cd_counter_rollers_installation_actions:
    'Prüfen sie die Leichtgängigkeit und Abstand der einzelnen Kabinentürgegenrollen.\n  Lockern sie jene Rollen, die nicht leichtgängig per Hand zu drehen sind oder der Abstand zur Schiene zu groß ist. Stellen sie alle gelockerten Rollen erneut so ein, dass sie so nahe wie möglich zur Schiene stehen, OHNE jedoch direkt Kontakt mit ihr zu haben.\n  Beachten sie bitte die Installationsinformation wenn zb. der Kuppler gelöst werden muss, um eine Rolle zu erreichen.',
  cd_counter_rollers_installation_description:
    'Die Kabinengegenrollen sind nicht korrekt angezogen.',
  cd_roller: 'Kabinenrollen',
  chooseFloorOrCardoor: 'Wähle Stockwerk / Fahrstuhlkorbtür',
  choosePartNumber: 'Teilenummer auswählen',
  chooseReason: 'Wählen Sie einen Grund (optional)',
  chooseStartingFloorInstructions:
    'Bitte wählen Sie das Stockwerk aus, in dem der Testlauf gestartet werden soll. Dies kann wahlweise das oberste oder das unterste Stockwerk sein.',
  clearDriveErrors: 'Löschen',
  clearOpening: 'Lichte Öffnung',
  close: 'Schließen',
  closeDoorInstructions: (floor: string) => (
    <>
      Begeben Sie sich ins <b>{floor == 'bottom' ? 'unterste' : 'oberste'}</b>{' '}
      Stockwerk. Stellen Sie sicher, dass die Aufzugtüren geschlossen sind.
    </>
  ),
  closeForcePotiDescription:
    'Eingestelltes Kraftlimit welches für die Öffnung und Schließung der Türe verwendet wird.',
  closeForcePotiNote:
    'Via Potentiometer kann dieser Wert auf älteren Elektroniken adjustiert werden. Bei neuere Elektroniken wie MIDIV2 wird dieser Wert hier bei Bedarf im Service Mode adjustiert (im Normalfall ist ein Verändern des Wertes nicht nötig wegen automatischem Selbstlernen).',
  closeForcePotiTitle: 'Schließkraft Limit',
  closeHoldForceDescription:
    'Mit diese Kraft wird im Geschlossenen Ende der Kuppler gehalten.',
  closeHoldForceNote:
    'Diese Kraft sollte normalerweise zwischen 50N and 60N eingestellt werden. Bei höheren Einstellungen kann der Motor Überhitzen und die Elektronik in Reduktion gehen, bei zu kleiner Einstellung kann es zu nicht erwünschten Bewegungen der Tür kommen.',
  closeHoldForceTitle: 'Haltekraft Geschlossen',
  closeSpeedDescription:
    'Maximale Türschließgeschwindigkeit, der Türantrieb wird versuchen diese Geschwindigkeit in der Schließbewegung zu erreichen.',
  closeSpeedNote:
    'Die Wirkliche Schließgeschwindigkeit ist von den DIP Schaltereinstellungen abhängig. DIP Schaltereinstellung auf Geschwindigkeit 4 bedeuted 100% der Maximalen Türschließgeschwindigkeit',
  closeSpeedTitle: 'Schließgeschwindigkeit',
  closing_device_failure: 'Schließeinrichtung',
  closing_device_failure_actions:
    'Kontrollieren sie die Funktion der Schließeinrichtung der betroffenen Schachttüre. Die Schachttüre alleine muss aus jeder Türposition aus eigener Kraft schließen.',
  closing_device_failure_description:
    'Die Schließeinrichtung wurde nicht korrekt installiert. Der Kraftaufwand zum Schließen der Tür ist zu hoch.',
  companyName: 'Firmenname',
  completed: 'Abgeschlossen',
  componentDetails: 'Details der Komponente',
  componentDetailsHint:
    'Für einige Komponenten gibt es mehrere Teilenummern, die ausgewählt werden müssen. Dies ist eine wichtige Information für die Nutzung der präventiven Wartungsfunktion. Bitte klicken Sie auf die aufgelisteten Elemente, um das tatsächlich installierte Teil auszuwählen.',
  confirm: 'Bestätigen',
  confirmDeleteBuilding: 'Möchten Sie dieses Gebäude wirklich löschen?',
  confirmDeleteErrorHistory:
    'Möchten Sie diese Fehlerhistorie wirklich löschen?',
  confirmDeleteFavoriteBuilding:
    'Möchten Sie dieses Gebäude wirklich aus Ihren Favoriten entfernen?',
  confirmDeleteLift: 'Möchten Sie diesen Aufzug wirklich löschen?',
  confirmInvite: 'Bestätigen und Verbinden',
  connectionErrorMessage:
    'Daten können aufgrund eines Verbindungsproblems nicht abgerufen werden. Bitte versuchen Sie es später erneut.',
  continue: 'Weiter',
  contractorBuilding: 'Gebäude des Auftraggebers',
  contractorBuildings: 'Gebäude des Auftraggebers',
  contractors: 'Auftraggeber',
  couplerelease: 'Kupplerentriegelungsrolle',
  couplerFriction: 'Kupplerreibung',
  couplerMovementParameter: 'Kuppler Parameter',
  couplerSpeedDescription:
    'Dieser Wert gibt die maximale Geschwindigkeit des Riemens im Kupplerbereich an.',
  couplerSpeedTitle: 'Kupplergeschwindigkeit',
  couplerWitdhDescription:
    'Dieser Wert gibt die Riemenlänge an, die in den Kupplerbereich fällt. Dieser Wert wird im Einlernvorgang ermittelt',
  couplerWitdhTitle: 'Kupplerweite',
  currentVersion: 'Aktuelle Version',
  couplerwidth: 'Kupplerbreite',
  date: 'Datum',
  delete: 'Löschen',
  deleteErrorHistory: 'Fehlerhistorie löschen',
  directConnection: (
    <>
      <p>
        Verbinden Sie sich mit dem WLAN der ELEVATORSENSE Box, indem Sie den auf
        der Verpackung aufgedruckten QR-Code mit der Kamera-App Ihres Telefons
        scannen.
      </p>
      <p>
        Sobald die Verbindung hergestellt ist, kehren Sie zu diesem Bildschirm
        zurück und klicken Sie unten auf die Schaltfläche <b>Weiter</b>.
      </p>
    </>
  ),
  disconnectProgrammingTool: 'Verbindung trennen',
  divpulley: 'Umlenkrolle',
  documentation: 'Dokumentation',
  doorContact: 'Türkontakt',
  doorCycleCounter: 'Türzyklen',
  doorCycleError1: 'Es wurden keine Daten für diesen Türzyklus empfangen.',
  doorCycleError2: 'Der Türzyklus war gestört.',
  doorCycleError4: 'Es wurde ein Re-Opening der Tür erkannt.',
  doorCycleError5: 'Es wurde ein Re-Closing der Tür erkannt.',
  doorCycleError7: 'Es wurden keine validen Türzyklen empfangen.',
  doorCycles: 'Türzyklen',
  doorFriction: 'Türreibung',
  doorInstallationDate: 'Datum des Türeinbaus',
  doorIsClosed: 'Ja, die Tür ist geschlossen',
  doorModel: 'Türmodell',
  doorModelNotSupported:
    'Das ausgewählte Türmodell ist nicht für die vorbeugende Wartung verfügbar.',
  doorMovementParameter: 'Tür Parameter',
  doorPosition: 'Tür Position',
  doorSerialNumber: 'Seriennummer der Tür',
  doorType: 'Türtyp',
  doorwidth: 'Türbreite',
  downloadCertificate: 'Zertifikat herunterladen',
  downloadLicense: 'Alle Lizenzen als CSV herunterladen',
  downloadRequestedExtendedWarranty:
    'Alle Anträge auf Garantieverlängerung herunterladen',
  drive_motor_noise: 'Motorgeräusch',
  driveesn: 'ESN',
  drivesoftware: 'Firmware',
  duringLiftMovement: 'Während der Aufzugsbewegung',
  editBuilding: 'Gebäude bearbeiten',
  editLift: 'Aufzug bearbeiten',
  elecBoard: 'Türsteuerplatine',
  elevatorIdNotFound: (
    <>
      Um eine erweiterte Garantie anzufordern, müssen Sie die Seriennummer der
      Tür in den Aufzugseinstellungen eingeben.{' '}
      <b>Klicken Sie hier, um zu den Einstellungen zu gelangen.</b>
    </>
  ),
  elevatorInstallationDate: 'Datum der Aufzugsinstallation',
  elevatorUsageType: 'Aufzug Verwendungsart',
  email: 'E-Mail',
  encoderTemperature: 'Encoder-Temperatur',
  error: 'Fehler',
  errorDescription: 'Fehlerbeschreibung',
  errorEarlierThanInstallationDate:
    'Es ist nicht möglich, ein früheres Datum als das Installationsdatum einzugeben.',
  errorInvalid: 'Bitte geben Sie einen gültigen Wert ein.',
  errorInvalidOperation: 'Unzulässige Operation',
  errorMessages: 'Fehlermeldungen',
  errorMessagesDescription: 'Anzeige von protokollierten Fehlermeldungen.',
  errorNotFound: 'Resource nicht gefunden',
  errorRetryCamera:
    'Kamera kann nicht zugegriffen werden. Erneuter Versuch, die Seite wird neu geladen...',
  errorRequired: 'Bitte füllen Sie das Feld aus.',
  errorUnableToAccessCamera:
    'Kamera kann nach mehreren Versuchen nicht zugegriffen werden. Bitte aktualisieren Sie die Seite oder überprüfen Sie die Kameraberechtigungen.',
  errorUnknownOrForbiddenBox:
    'Diese Box ist nicht vorhanden. Bitte kontaktieren Sie Ihren Wittur Service, falls Sie Unterstützung benötigen.',
  errors: 'Fehler',
  errorsEmpty: 'Keine Meldungen',
  errorsEmptyHint:
    'Bitte überprüfen Sie Ihre Filter und aktivieren Sie gegebenenfalls weitere Typen.',
  extendWarranty: 'Garantie verlängern',
  fatalError: 'Kritischer Fehler',
  fatalErrors: 'Kritische Fehler',
  favorite: 'Favorit',
  favorites: 'Favoriten',
  figureAdjustment: 'Einstellvorgang',
  figureAugustaEVO: 'Beispiel Augusta EVO',
  figureClosingWeight: 'Schließgewicht',
  figureEco: 'ECO+',
  figureHydra: 'Beispiel Hydra',
  figureMidi: 'MIDI/SUPRAV2',
  figureNotOk: 'Nicht OK',
  figureOk: 'OK',
  figureSGV: 'Beispiel SGV',
  figureSpirator: 'Spirator',
  figureSpring: 'Feder',
  firstName: 'Vorname',
  floor: 'Stockwerk',
  floors: 'Stockwerke',
  followingBoxScanned: 'Die folgende Box wurde gescannt',
  forgotPassword: 'Passwort vergessen?',
  forgotPasswordText:
    'Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.',
  groundFloor: 'Erdgeschoss',
  home: 'Übersicht',
  incompleteElevatorSettings:
    'Um die präventive Wartung nutzen zu können, benötigen wir weitere Informationen zu diesem Aufzug. Bitte gehen Sie zu Ihren Aufzugseinstellungen und geben Sie die fehlenden Informationen ein.',
  initialized: 'Vorbereitet',
  installationControl: 'Installationskontrolle',
  installationControlDescription:
    'Automatische Tests zum Erkennen und Beheben von Installationsfehlern.',
  installationControlExecutedBy: 'Durchgeführt von',
  installationControlHeadline: 'Neuester Installationsstatus',
  installationControlHistory: 'Verlauf',
  installationControlHistoryEmpty: 'Bislang keine Läufe durchgeführt',
  installationControlInstructions: 'Zur Anleitung',
  installationControlSubheadline: 'Zusammenfassung aller Läufe',
  installationDateErrorMessage:
    'Es ist nicht möglich, ein früheres Tür-Installationsdatum als das Installationsdatum des Aufzugs einzugeben',
  instantSubscription: 'Sofort Benachrichtigung',
  instantSubscriptionExplanation: (
    <>
      Die <b>Sofort-Benachrichtigung</b> ist eine unmittelbare Benachrichtigung
      per E-Mail sobald ein kritischer Fehler auf einem Fahrstuhl aufgetreten
      ist. Kritische Fehler sind Fehler, die zum sofortigen Ausfall des
      Fahrstuhls führen können.
    </>
  ),
  invitationExpired: 'Der Einladungs-Link ist nicht mehr gültig.',
  inviteAsManager: 'Nutzer darf andere Nutzer einladen',
  inviteCompanyWithSubcontractorsError:
    'Das Unternehmen, das Sie einladen möchten, hat bereits Subunternehmer und kann​',
  inviteCoworkers: 'Mitarbeiter einladen',
  inviteSubcontractor: 'Subunternehmer einladen',
  inviteToSignUpMessage: (companyName: string) =>
    `Das Unternehmen ${companyName} hat Sie eingeladen, sich bei ElevatorSense als ihr Subunternehmer anzumelden. Bitte füllen Sie das Formular aus, um sich zu registrieren.`,
  inviteToBeSubcontractorMessage: (companyName: string) =>
    `${companyName} hat Sie eingeladen, ihr Subunternehmer in ElevatorSense zu sein.`,
  ipaddress: 'IP-Adresse',
  joinText: (name: string) => (
    <>
      Bitte registrieren Sie sich, um der <i>{name}</i> Organisation
      beizutreten.
    </>
  ),
  landingDoor: 'Schachttür - Stockwerk',
  landing_lock_roller_adjustment: 'Schachttür Hackenriegelrollen Einstellung',
  landingCarDoorSpareParts: 'Schachttür / Fahrstuhlkorbtür Ersatzteile',
  lastError: 'Letzte Fehlermeldung',
  lastExchangeDate: 'Letztes Austauschdatum',
  lastMaintenanceDate: 'Datum der letzten Wartung',
  lastMaintenanceScope: 'Letzter Wartungsumfang',
  lastMaintenanceScopeHint:
    'Wählen Sie den von Wittur empfohlenen Wartungsumfang, der am letzten Wartungstermin tatsächlich durchgeführt wurde.',
  lastName: 'Nachname',
  lastTimeDoorElectronicsChange: 'Datum des Austausches der Türelektronik',
  latestSoftwarePackVersion: (version: string) =>
    `Softwarepaket-Version: ${version}`,
  ld_counter_rollers_installation: 'Gegenrollen',
  ld_counter_rollers_installation_actions:
    'Prüfen sie die Leichtgängigkeit und Abstand der einzelnen Schachttürgegenrollen.\n  Lockern sie jene Rollen, die nicht leichtgängig per Hand zu drehen sind oder der Abstand zur Schiene zu groß ist. Stellen sie alle gelockerten Rollen erneut so ein, dass sie so nahe wie möglich zur Schiene stehen, OHNE jedoch direkt Kontakt mit ihr zu haben.',
  ld_counter_rollers_installation_description:
    'Die Schachttürgegenrollen sind nicht korrekt angezogen.',
  ld_roller: 'Schachttürrollen',
  left: 'Links',
  licenseRequired:
    'Für dieses Produkt wurde noch keine gültige Lizenz erworben. Um das Produkt zu erwerben, kontaktieren Sie bitte Ihren Wittur Ansprechpartner.',
  lift: 'Aufzug',
  liftMovementDistance: 'Zurückgelegte Distanz',
  liftSetup: 'Einrichtung',
  liftSetupComplete: 'Einrichtung abgeschlossen.',
  liftSetupError: 'Die zugehörigen Daten sind unvollständig oder fehlen.',
  liftSetupError40: 'Die Fahrstuhlbewegung passt nicht zum Einrichtungslauf.',
  liftSetupIncomplete: 'Einrichtung unvollständig.',
  liftSetupPending:
    'Bitte führen Sie den Einrichtungslauf zur initialen Einrichtung von ElevatorSense auf Ihrem Fahrstuhl durch. Ohne den Einrichtungslauf können Sie einige Funktionalitäten der ElevatorSense Produkte nicht nutzen bzw. die Ergebnisse der ElevatorSense Produkte können abweichen. Klicken Sie hier, um den Einrichtungslauf durchzuführen.',
  liftSetupRepeat:
    'Der Einrichtungslauf war nicht erfolgreich. Bitte führen Sie den Lauf unter Berücksichtigung der Prozessbeschreibung erneut durch.',
  lifts: 'Aufzüge',
  linkBox: 'Box verknüpfen',
  linkToWebshop: (link: string) => (
    <>
      Wenn Sie eine ElevatorSense-Box haben und eine Lizenz aktivieren möchten,
      klicken Sie bitte{' '}
      <a href={link} className="clickable-link">
        hier
      </a>
      .
    </>
  ),
  loading: 'Lade Daten',
  login: 'Anmelden',
  loginText: 'Bitte melden Sie sich an.',
  logout: 'Abmelden',
  mandatory: 'Prüfung des Austauschs durchführen',
  manageAccess: 'Zugriff verwalten',
  manageElevators: 'Aufzüge verwalten',
  manageSharedBuildingsAndElevator: 'Gemeinsame Gebäude/Aufzüge verwalten',
  manual: 'Manuell',
  maxElectronics: 'Max. Aufzüge',
  maxUsage: 'Nutzungslimit',
  measured: 'Gemessen',
  members: 'Mitarbeiter',
  misalignment_cd_vs_ld: 'Ausrichtung Tür/Kuppler',
  misalignment_cd_vs_ld_actions:
    'Das System muss so eingestellt werden, dass das Durchfahrtsspiel zwischen Kuppler und Schachttür-Kupplerrollen auf beiden Seiten im Idealfall gleich ist.',
  misalignment_cd_vs_ld_description:
    'Der Kuppler und die Kabinentür sind nicht korrekt zur Schachttür ausgerichtet.',
  misalignment_cd_vs_ld_sill_gap: 'Türschwellenspalt',
  misalignment_cd_vs_ld_sill_gap_actions:
    'Die Abstände X/Y zwischen den Schwellen links und rechts muss gleich sein (X=Y). Falls dieser Fehler in allen Stockwerken auftritt, ist die Montage der Kabinentüre richtigzustellen.',
  misalignment_cd_vs_ld_sill_gap_description:
    'Die Schachttür ist nicht parallel zur Kabinentür ausgerichtet',
  mode: 'Modus',
  monitoring: 'Diagnose',
  motor: 'Motor',
  motorTemperature: 'Motortemperatur',
  myBuildingsSubscription: 'Nur von meinen Favoriten',
  name: 'Name',
  networkQuality: 'Netzwerkqualität',
  newInstallationControl: 'Neue Installationskontrolle',
  next: 'Vor',
  nextMaintenanceDate: 'Datum der nächsten Wartung',
  no: 'Nein',
  notAuthorizedToConfirmInviteError:
    'Sie sind nicht berechtigt, diese Einladung zu bestätigen, da sie nicht für Ihren angemeldeten Benutzer bestimmt ist.',
  noBoxLinked:
    'Der Lift ist noch mit keiner Box verknüpft. Um alle Funktionen der App zu nuzten, scannen Sie den QR-Code der Box oder geben Sie die Box-ID von Hand ein.',
  noBuildingsVisible:
    'Keine Gebäude sind in der aktuellen Kartenansicht sichtbar. Versuchen Sie, die Karte anzupassen, um weitere Gebäude zu sehen.',
  noComponentsFound: 'Keine Komponenten für dieses Stockwerk gefunden',
  noFavorite: 'Kein Favorit',
  noFavoritesChoosen:
    'Sie haben noch keine Lieblingsgebäude ausgewählt. Navigieren Sie zu Ihrem Gebäude und klicken Sie auf das Sternsymbol, um es zu Ihren Lieblingsgebäuden hinzuzufügen.',
  noFloorInfoAvailable: 'Keine Etageninformationen verfügbar',
  noInstallationControlFound:
    'Die ESN der Türelektronik stimmt nicht überein mit der ESN des letzten Installation Control Laufs. Bitte prüfen Sie, ob Sie mit dem korrekten Fahrstuhl verbunden sind.',
  noSubcontractors: 'Keine Subunternehmer gefunden.',
  noSubcontractorMessage:
    'Derzeit sind keine Subunternehmer mit Ihrem Unternehmen verknüpft. Um einen zu verknüpfen, senden Sie eine Einladung.',
  noSubscription: 'Keine',
  note: 'Hinweis',
  noQRCodeDetected:
    'Kein QR-Code erkannt. Bitte bewegen Sie die Kamera, damit der QR-Code scharfgestellt und vollständig im Sichtfeld sichtbar ist.',
  nudgingSpeedDescription:
    'Wenn aktiv, wird der Türantrieb versuchen, die Drängelgeschwindigkeit in der Schließbewegung zu erreichen.',
  nudgingSpeedNote:
    'Die Wirkliche Schließgeschwindigkeit ist von den DIP Schaltereinstellungen abhängig. DIP Schaltereinstellung auf Geschwindigkeit 4 bedeuted 100% der Maximalen Drängelgeschwindigkeit.',
  nudgingSpeedTitle: 'Drängelgeschwindigkeit',
  obsolete: 'Obsolet',
  offline: 'Offline',
  offlineConnection:
    'Sie sind zur Zeit offline. Sie können jedoch das Werkzeug zur Programmierung benutzen.',
  ok: 'Reguläre Maintenance',
  online: 'Online',
  openHoldForceDescription:
    'Kraft mit der die Tür im Geöffneten Zustand aufgedrückt wird',
  openHoldForceNote:
    'Diese Kraft sollte normalerweise zwischen 50N and 60N eingestellt werden. Bei höheren Einstellungen kann der Motor Überhitzen und die Elektronik in Reduktion gehen, bei zu kleiner Einstellung kann es zu nicht erwünschten Bewegungen der Tür kommen',
  openHoldForceTitle: 'Haltekraft Geöffnet',
  openSpeedDescription:
    'Maximale Türöffnungsgeschwindigkeit, der Türantrieb wird versuchen diese Geschwindigkeit in der Öffnungsbewegung zu erreichen.',
  openSpeedNote:
    'Die Wirkliche Öffnungsgeschwindigkeit ist von den DIP Schaltereinstellungen abhängig. DIP Schaltereinstellung auf Geschwindigkeit 4 bedeuted 100% der Maximalen Türöffnungsgeschwindigkeit.',
  openSpeedTitle: 'Öffnungsgeschwindigkeit',
  otherReason: 'Anderer Grund',
  overdue: 'Überfällig',
  parameter: 'Parameter',
  partInformation: 'Teileinformationen',
  partIsStillOk: 'Teil ist noch in Ordnung',
  partNumber: 'Teilenummer',
  partName: 'Teilname',
  password: 'Passwort',
  passwordResetSent:
    'Wir haben Ihnen eine E-Mail zum Zurücksetzten des Passworts gesendet. Bitte überprüfen Sie Ihren Posteingang.',
  pendingInvites: 'Ausstehende Einladungen',
  pendingUserInvites: 'Ausstehende Benutzereinladungen',
  pendingSubcontractorInvites: 'Ausstehende Subunternehmer-Einladungen',
  planningAndAlerts: 'Planung und Warnungen',
  position: 'Position',
  positionOutDescription:
    'Es definiert jene Position in welcher das Ausgangsrelais der Elektronik geschaltet wird (FPC Abhängig). Standardeinstellung ist 0 (deaktiviert).',
  positionOutNote:
    'Wird der Parameter gesetzt, schaltet das Relais bei erreichen der Position um. Diese Funktion wird für voreilende Anlagen verwendet.',
  positionOutTitle: 'Positionsschalter',
  postpone: 'Verschieben',
  powerReductionFactor: 'Leistungsreduktion',
  predicted: 'Vorhergesagt',
  prev: 'Zurück',
  preventiveMaintenance: 'Präventive Wartung',
  preventiveMaintenanceDescription:
    'Tauschen Sie Ihre Fahrstuhltür Ersatzteile aus, bevor sie kaputt gehen.',
  preventiveMaintenanceUnavailable:
    'Die eingegebenen Daten sind vollständig! ElevatorSense berechnet nun den Wartungsplan basierend auf der Nutzung Ihres Elevators. Dieser Vorgang kann einige Minuten dauern.',
  priority: 'Priorität',
  product: 'Produkt',
  programmingDisabledHint:
    'Die Programmierung der Elektronik ist zur Zeit deaktiviert. Bitte stellen Sie sicher, dass sich die Elektronik im Service Drive Mode befindet um die Programming des Gerätes zu ermöglichen.',
  programmingErrorConnection: (
    <>
      <p>
        Wir haben Probleme beim Verbindungsaufbau mit der ELEVATORSENSE Box
        festgestellt.
      </p>
      <p>
        Bitte schalten Sie Ihre "Mobile Datenverbindung" aus und stellen Sie
        sicher, dass Sie nur mit dem WLAN der ELEVATORSENSE Box verbunden sind.
      </p>
      <p>
        Anschließend klicken Sie unten auf die Schaltfläche <b>Weiter</b>.
      </p>
    </>
  ),
  programmingErrorNoPopup:
    'Neues Fenster konnte nicht geöffnet werden. Stellen Sie sicher, dass Popups in Ihrem Browser erlaubt sind.',
  programmingErrorRequest:
    'Anfrage gescheitert. Bitte stellen Sie sicher, dass Ihr Gerät sich im WIFI Netzerk der ELEVATORSENSE Box befindet.',
  programmingTool: 'Wireless Programming',
  programmingToolDescription: 'Einfache Konfiguration der Türelektronik.',
  pulley_is_touching_belt: 'Riemen streift an der Rolle',
  pulley_is_touching_belt_actions:
    'Stellen Sie den Riemen so ein, dass er die Bordscheibe nicht berührt.',
  pulley_is_touching_belt_description: 'Der Riemen streift an der Bordscheibe.',
  readyToRunTest: 'Bereit zum Starten des Testlaufs.',
  realtimeMonitoringDescription:
    'Anzeige von Messwerten und Fahrkurven in Echtzeit.',
  realtimeMonitoringTitle: 'Echtzeitdiagnose',
  recommended: 'Prüfung des Austauschs planen',
  recommendedActions: 'Empfohlene Maßnahmen',
  recommendedDateOfExchange: 'Empfohlenes Datum um den Austausch zu planen',
  reload: 'Aktualisieren',
  remove: 'Entfernen',
  reopenTimeDescription:
    'Der Reopen Parameter definiert die Wartezeit nach dem Automatischen Umsteuern im Reopen Fall in der Offenposition, vor dem Erneuten schließen der Tür.',
  reopenTimeNote:
    'Diese Funktion ist in Verwendung, wenn das Umsteuern im Reopen-Fall der Elektronik überlassen wird und nicht dem Liftkontroller.',
  reopenTimeParameter: 'Wiederöffnungszeit Parameter',
  reopenTimeTitle: 'Wiederöffnungszeit Parameter',
  repeatFloor:
    'Bitte wiederholen Sie den Installation Control Prozess für dieses Stockwerk!',
  repeatWholeRun: (
    <>
      Bitte wiederholen Sie den <b>kompletten</b> Installation Control Prozess!
    </>
  ),
  replaced: 'Ausgetauscht',
  replacementWasPostponed: 'Der Austausch wurde verschoben am:',
  requestNewLink: 'Neuen Link anfordern',
  resetPassword: 'Passwort zurücksetzen',
  resetPasswordText: 'Bitte geben Sie ein neues Passwort ein.',
  revoke: 'Widerrufen',
  right: 'Rechts',
  rollerBumper: 'Schlossrolle',
  rollerSynchronisationRope: 'Umlenkrolle Synchro.Seil',
  runs: 'Läufe',
  save: 'Speichern',
  scan: 'Scannen',
  scanned: 'Gescannt',
  scanAgain: 'Erneut scannen',
  searchPlaces: 'Ort suchen',
  select: 'Wähle',
  selectCameraMode: 'Kameramodus auswählen',
  selectClearOpening: 'Lichte Öffnung auswählen',
  selectDoorModel: 'Wähle Türmodell',
  selectDoorType: 'Türtyp auswählen',
  selectModifiedFloors:
    'Bitte wählen Sie die Stockwerke aus, bei denen seit dem letzten Testlauf Veränderungen vorgenommen wurden. Die Stockwerke, in denen es beim letzten Lauf Fehler gab, sind vorausgewählt.',
  selectPartsHint:
    'Bitte prüfen Sie, welches Teil installiert ist und klicken Sie auf das entsprechende Bild.',
  send: 'Senden',
  sendResults: 'Ergebnis senden',
  sendTo: 'Ergebnis an die folgenden Email-Adressen senden:',
  sensorData: 'Sensordaten',
  serviceDriveSwitch: 'Schalter für Wartungsantrieb',
  settings: 'Einstellungen',
  share: 'Teilen',
  shareBody: (link: string) => `Link zum Testergebnis:\n${link}`,
  shareResults: 'Ergebnis teilen',
  shareSubject: 'ELEVATORSENSE Ergebnis',
  shoe: 'Führungsschuh',
  showPassedTests: 'Fehlerfreie Ergebnisse anzeigen ...',
  showTerms: 'Nutzungsbedingungen anzeigen',
  signUp: 'Registrieren',
  skid: 'Rullo',
  slideingshoe_failure: 'Gleitschuh Fehler',
  slideingshoes_usage: 'Gleitschuh Abnutzung',
  somethingWentWrong:
    'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut.',
  sortBy: 'Sortieren nach',
  sortByDate: 'Datum',
  sortByFloor: 'Etagennummer',
  sparePartDoesNotFit: 'Ersatzteil passt nicht',
  sparePartNotAvailable: 'Ersatzteil nicht verfügbar',
  speed: 'Geschwindigkeit',
  start: 'Start',
  startNewTestRun: 'Neuen Testlauf starten',
  started: 'Läuft',
  starting_floor: 'Startstockwerk',
  status: 'Status',
  subcontractorNotFound: 'Subunternehmer nicht gefunden',
  subcontractors: 'Subunternehmer',
  subcontractorManagement: 'Subunternehmerverwaltung',
  subscriptionHint:
    'Sie erhalten E-Mail-Benachrichtigungen nur sofern Sie entsprechende Produkte für Ihre ElevatorSense Box gekauft haben.',
  subscriptionText:
    'Bitte wählen Sie welche E-Mail-Benachrichtigungen Sie erhalten möchten.',
  subscriptions: 'Benachrichtigungen',
  summarySubscription: 'Tägliche Zusammenfassung',
  summarySubscriptionExplanation: (
    <>
      Die <b>tägliche Zusammenfassung</b> umfasst eine E-Mail mit einer
      Übersicht über alle ausgewählten Gebäude und Fahrstühle mit der Angabe der
      Anzahl an Fehlern.
    </>
  ),
  temperature: 'Temperatur',
  termsOfUse: {
    general: {
      title: 'GENERAL TERMS AND CONDITIONS OF USE OF ELEVATORSENSE APPLICATION',
      sections: [
        {
          title: 'I. Allgemeines',
          content: [
            <>
              Die Wittur GmbH (nachfolgend <strong>"WITTUR"</strong>,{' '}
              <strong>"Wir"</strong>) bietet Nutzern (<strong>"Nutzer"</strong>
              ), entweder selbst oder über nachfolgend definierte Affiliates,
              eine Lösung an, die aus Hardware besteht, welche Daten von
              Personen- und Lastenaufzügen in Gebäuden oder deren Bestandteilen
              erfasst (die <strong>"ElevatorSense Box"</strong>), sowie aus der
              ElevatorSense Mobil- und Webanwendung (nachfolgend{' '}
              <strong>"Mobile App"</strong>, <strong>"Web App"</strong> und
              gemeinsam <strong>"App"</strong>). (Sowohl die ElevatorSense Box
              als auch die App nachfolgend{' '}
              <strong>"ElevatorSense Lösung"</strong>).
            </>,
            <>
              Die Bereitstellung der ElevatorSense Box wird durch einen
              separaten Vertrag zwischen dem Nutzer und WITTUR (
              <strong>"Box-Vertrag"</strong>) geregelt.
            </>,
            <>
              Die App kann auch von Affiliates der WITTUR angeboten werden,
              wobei WITTUR in einem solchen Fall alleiniger Vertragspartner des
              Nutzers (wie nachfolgend definiert) in Bezug auf die
              Bereitstellung der App bleibt. <strong>"Affiliate"</strong>{' '}
              bezeichnet alle Unternehmen, die mehrheitlich im Besitz von oder
              mehrheitlich aneinander beteiligt sind, abhängige und herrschende
              Unternehmen, Unternehmen unter einheitlicher Leitung eines
              herrschenden Unternehmens sowie Unternehmen, die aneinander
              Anteile oder Beteiligungen halten.
            </>,
            'WITTUR stellt die App ausschließlich Unternehmern zur Verfügung. Ein Unternehmer ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt. Verbraucher dürfen die App nicht nutzen.',
            <>
              Die nachfolgenden Bedingungen regeln vollständig die
              Rechtsbeziehungen zwischen WITTUR und dem Nutzer im Hinblick auf
              die App (<strong>"Nutzungsbedingungen"</strong>) und umfassen{' '}
              <i>unter anderem</i> den Zugriff auf und die Nutzung der App,
              vorbehaltlich zusätzlicher oder abweichender Bestimmungen, wie in{' '}
              <a href="#appendix_1">Anhang 1</a> angegeben. Die
              Nutzungsbedingungen werden mit Abschluss der Registrierung durch
              den Nutzer bindend, und zu diesem Zeitpunkt kommt zwischen WITTUR
              und dem Nutzer ein Dienstleistungsvertrag zustande (
              <strong>"Vertrag"</strong>). Die Nutzungsbedingungen werden von
              WITTUR gespeichert und können vom Nutzer jederzeit innerhalb der
              App eingesehen werden.
            </>,
            'Als Unternehmer hat der Nutzer kein Widerrufsrecht.',
          ],
        },
        {
          title: 'II. Anwendungsbereich',
          content: [
            <>
              Die App ist eine mobile und webbasierte Anwendung, die Dienste
              gemäß dem Box-Vertrag (<strong>"Dienste"</strong>) ermöglicht. Die
              App stellt zudem Informationen, Visualisierungen,
              Benachrichtigungen, Anweisungen und Bewertungen bereit, anhand
              derer geeignete Maßnahmen ergriffen werden können (
              <strong>"Empfehlungen"</strong>). Ein Hyperlink zum WITTUR-Webshop
              für Komponenten der ElevatorSense Lösung wird dem Nutzer zusammen
              mit den Empfehlungen in der App bereitgestellt.
            </>,
            <>
              Die Nutzung der App unterliegt ausschließlich diesen
              Nutzungsbedingungen sowie den in der App bereitgestellten
              Informationen. Abweichende oder widersprechende
              Geschäftsbedingungen werden nur dann Bestandteil einer
              Vereinbarung mit WITTUR, wenn WITTUR ihnen ausdrücklich
              schriftlich zugestimmt hat. Ungeachtet des vorstehenden Satzes ist
              sich der Nutzer bewusst, dass zusätzlich zu diesen
              Nutzungsbedingungen gegebenenfalls weitere Nutzungsbedingungen des
              jeweiligen App-Store-Betreibers (z. B. Google im Falle des Google
              Play Store oder Apple im Falle des Apple App Store) einzuhalten
              sind, wenn der Nutzer die Mobile App aus dem jeweiligen App-Store
              nutzt, und dass WITTUR auf solche weiteren Nutzungsbedingungen
              keinen Einfluss hat und folglich dafür keinerlei Verantwortung
              übernimmt.
            </>,
            'Das Herunterladen der Mobile App erfordert geeignete Endgeräte und Internetzugang, durch den Kosten für die Verbindung mit dem Google Play Store / Apple App Store anfallen können.',
            <>
              Die Parteien erkennen an und stimmen zu, dass für die in den in{' '}
              <a href="#appendix_1">Anhang 1</a> genannten lokalen
              Rechtsordnungen zu erbringenden Dienste zusätzliche oder
              abweichende Bedingungen oder Anforderungen gelten, die zu den in
              diesen Nutzungsbedingungen festgelegten hinzutreten oder an deren
              Stelle treten. Daher unterliegt das Vertragsverhältnis zwischen
              den Parteien, sofern zutreffend, zusätzlichen oder abweichenden
              Bedingungen gemäß <a href="#appendix_1">Anhang 1</a> (nachfolgend:{' '}
              <strong>"Lokale Rechtsbedingungen"</strong>). Im Falle eines
              Konflikts zwischen diesen Nutzungsbedingungen und den Lokalen
              Rechtsbedingungen haben die Lokalen Rechtsbedingungen Vorrang.
            </>,
          ],
        },
        {
          title: 'III. Vergütung',
          content: [
            'Die Nutzung der Basisfunktionen der App ist kostenlos. Der Nutzer ist jedoch darüber im Bilde, dass die App nur in Verbindung mit der ElevatorSense Box nutzbar ist, für die unterschiedliche Kauf- oder Mietmodelle bestehen, welche dem Box-Vertrag unterliegen.',
            <>
              Für spezielle, innerhalb der App verfügbare Funktionen, die vom
              Nutzer erworben werden können (<strong>"Features"</strong>),
              können zusätzliche Kosten anfallen. Die dem Nutzer verfügbaren
              Features können je nach Land, in dem die App genutzt wird,
              variieren.
            </>,
          ],
        },
        {
          title: 'IV. Bereitstellung der App; Wartung',
          content: [
            <>
              WITTUR gewährt einen angemessenen Zugang und eine angemessene
              Nutzung der App. Zu diesem Zweck macht WITTUR die App
              registrierten, festangestellten und/oder freiberuflichen
              Mitarbeitern des Nutzers (
              <strong>"Autorisierte Mitarbeiter"</strong>) zugänglich.
            </>,
            'WITTUR ist nach eigenem, freiem Ermessen berechtigt, den Zugang zur App ganz oder teilweise, vorübergehend oder dauerhaft aufgrund von Wartungsarbeiten, Kapazitätsbelangen oder anderen Ereignissen, die außerhalb ihrer Kontrolle liegen, einzuschränken. WITTUR wird die App in der Regel außerhalb der normalen Geschäftszeiten warten, es sei denn, ein unmittelbares Ereignis erfordert nach alleinigem, freiem Ermessen von WITTUR eine Wartung während der normalen Geschäftszeiten. Teile der App können sich jederzeit und ohne Vorankündigung nach alleinigem, freiem Ermessen von WITTUR ändern.',
            'WITTUR stellt den Zugang zur Web App in einer gehosteten Umgebung bereit, die es dem Nutzer ermöglicht, die App über das Internet zu nutzen, ohne die Software auf seiner eigenen IT-Infrastruktur installieren und betreiben zu müssen.',
            'WITTUR wird angemessene Anstrengungen unternehmen, um die Verfügbarkeit der Web App für die Nutzung durch den Nutzer am Übergangspunkt zwischen dem öffentlichen Internet und dem Netzwerk der Hosting-Server von WITTUR sicherzustellen. Die Web App gilt als verfügbar, wenn sie am Übergangspunkt zwischen dem öffentlichen Internet und dem Netzwerk der Hosting-Server von WITTUR nutzbar ist.',
            <>
              WITTUR stellt Aktualisierungen der App gemäß dem anerkannten Stand
              der Technik ohne zusätzliche Vergütung bereit (
              <strong>"Updates"</strong>). Updates im Sinne dieser
              Nutzungsbedingungen sind durch Fehlerbehebungen oder geringfügige
              andere Verbesserungen ohne wesentliche neue Funktionalitäten
              gekennzeichnet. Updates umfassen nicht die Lieferung oder
              Bereitstellung zusätzlich angebotener neuer Funktionalitäten oder
              zusätzlicher Komponenten.
            </>,
            <>
              Der Nutzer muss Updates für die Mobile App (d. h. clientseitig)
              installieren, sobald sie verfügbar sind. Updates ermöglichen es
              der Mobile App, stets aktuelle Informationen zu erhalten und ihren
              ordnungsgemäßen Betrieb sicherzustellen. Andernfalls kann WITTUR
              nicht gewährleisten, dass die Mobile App ordnungsgemäß
              funktioniert oder dass die bereitgestellten Informationen aktuell
              sind. Sollte der Nutzer keine Updates installieren, die veraltete
              Version der Mobile App jedoch weiterhin nutzen, verzichtet der
              Nutzer damit auf jegliche Ansprüche in Bezug auf Mängel und
              Ansprüche auf Schadensersatz, die ihm möglicherweise zustehen.
            </>,
          ],
        },
        {
          title: 'V. Benutzerkonto',
          content: [
            <>
              Der Zugriff auf die App darf dem Nutzer und seinem Autorisierten
              Personal erst nach der Registrierung und Erstellung eines
              Benutzerkontos innerhalb der App (<strong>"Benutzerkonto"</strong>
              ) gewährt werden.
            </>,
            'Der Nutzer muss sein Autorisiertes Personal gegenüber WITTUR benennen.',
            'Der Nutzer ist allein und ausschließlich dafür verantwortlich, sein Autorisiertes Personal zu verwalten, z. B. Rollen und Rechte innerhalb der App zuzuweisen. Insbesondere muss der Nutzer den Zugang zur App beenden, sobald die jeweiligen Personen nicht mehr für den Nutzer tätig sind.',
            <>
              Der Nutzer ist verpflichtet, seine Zugangsdaten zu seinem
              Benutzerkonto geheim zu halten und vor dem Zugriff Dritter zu
              schützen. Sobald der Nutzer Kenntnis vom Verlust oder Missbrauch
              seiner Zugangsdaten oder vom Verdacht eines Missbrauchs seines
              Benutzerkontos erlangt, muss er WITTUR unverzüglich
              benachrichtigen und das Passwort über die in der App
              bereitgestellte Funktion ändern. Eine Weitergabe der Zugangsdaten
              an Dritte ist untersagt. Der Nutzer darf nicht die Zugangsdaten
              einer anderen Person verwenden, um auf die App zuzugreifen. Der
              Nutzer ist allein und ausschließlich für die Aktivitäten jedes
              Nutzers verantwortlich, der mit den Zugangsdaten des Nutzers auf
              die App zugreift, einschließlich des Autorisierten Personals,
              selbst wenn diese Aktivitäten tatsächlich nicht vom Nutzer
              autorisiert wurden.
            </>,
          ],
        },
        {
          title: 'VI. Pflichten des Nutzers',
          content: [
            <>
              Der Nutzer ist allein und ausschließlich für jegliche Inhalte
              verantwortlich, die er über die App hochlädt, veröffentlicht oder
              anderweitig öffentlich zugänglich macht. Durch die Nutzung eines
              Kontos in der App erklärt und gewährleistet der Nutzer gegenüber
              WITTUR, dass die von ihm bereitgestellten Informationen (
              <strong>"Nutzerinhalte"</strong>) wahr, korrekt und vollständig
              sind. Darüber hinaus garantiert der Nutzer, die Nutzerinhalte
              wahr, korrekt und vollständig zu halten. WITTUR überwacht die
              Nutzerinhalte nicht auf Vollständigkeit, Richtigkeit,
              Rechtmäßigkeit, Verfügbarkeit, Qualität oder Eignung für einen
              bestimmten Zweck.
            </>,
            <>
              Die Dienste können nur durchgeführt werden, solange die
              ElevatorSense Box mit dem Internet verbunden ist. Der Nutzer
              erkennt an, dass es in seiner alleinigen und ausschließlichen
              Verantwortung liegt, die ElevatorSense Box technisch
              bereitzustellen. Der Nutzer verzichtet hiermit auf jegliche Rechte
              und daraus resultierende Ansprüche, auf die der Nutzer aufgrund
              von Verbindungsstörungen der ElevatorSense Box Anspruch haben
              könnte.
            </>,
            'Um die Dienste durchzuführen, ist der Nutzer verpflichtet, die in der App bereitgestellten Installations- und Betriebsanweisungen strikt zu befolgen.',
            <>
              Wenn WITTUR herunterladbare Updates und/oder Upgrades für die
              ElevatorSense Box bereitstellt, ist der Nutzer verpflichtet, diese
              zu installieren, um eine ordnungsgemäße Interaktion zwischen der
              App und der ElevatorSense Box sicherzustellen. Andernfalls kann
              WITTUR nicht gewährleisten, dass die Dienste ordnungsgemäß
              ausgeführt werden können. Sollte der Nutzer ein bereitgestelltes
              Update und/oder Upgrade nicht installieren, jedoch weiterhin die
              veraltete Version der ElevatorSense Box verwenden, verzichtet der
              Nutzer damit auf jegliche Ansprüche hinsichtlich Mängeln und
              Schadenersatzansprüche, auf die er möglicherweise Anspruch hätte.
            </>,
            'Dem Nutzer ist es untersagt, auf oder im Zusammenhang mit der App irgendwelche Aktivitäten auszuführen, die gegen geltendes Recht verstoßen oder die Rechte Dritter verletzen.',
            {
              text: 'Darüber hinaus ist es dem Nutzer untersagt, die folgenden Aktivitäten auszuführen, unabhängig von einem Gesetzesverstoß:',
              list: [
                'die Verbreitung von Viren, Trojanern und anderen schädlichen Dateien;',
                'das Versenden von Junk- oder Spam-Mails und Kettenbriefen;',
                'die Verbreitung von anstößigen, sexuell orientierten, obszönen oder diffamierenden Inhalten oder Mitteilungen sowie solcher Inhalte oder Mitteilungen, die geeignet sind, Rassismus, Fanatismus, Hass, körperliche Gewalt oder illegale Handlungen zu fördern oder zu unterstützen (entweder ausdrücklich oder stillschweigend in jedem Fall);',
                'die Aufforderung anderer Nutzer, Passwörter oder persönliche Daten für kommerzielle oder illegale Zwecke offenzulegen;',
                'die Verbreitung und/oder öffentliche Wiedergabe von Inhalten, die in der App verfügbar sind.',
              ],
            },
            'WITTUR behält sich das Recht vor, den Nutzer jederzeit von der Nutzung der App auszuschließen, wenn gegen diese Nutzungsbedingungen, geltendes Recht oder die Rechte Dritter verstoßen wird oder WITTUR vernünftige Gründe hat anzunehmen, dass ein Verstoß vorliegen könnte.',
          ],
        },
        {
          title: 'VII. Geistige Eigentumsrechte',
          content: [
            <>
              WITTUR gewährt dem Nutzer ein widerrufliches, nicht-exklusives,
              nicht übertragbares, nicht unterlizenzierbares und auf die
              Laufzeit des Vertrags begrenztes Recht, die App gemäß diesen
              Nutzungsbedingungen zu nutzen (<strong>"Lizenz"</strong>). Inhaber
              der geistigen Eigentumsrechte an der App ist WITTUR oder deren
              Geschäftspartner oder andere Dritte, die die jeweiligen Inhalte
              und geistigen Eigentumsrechte lizenziert an WITTUR zur Verfügung
              gestellt haben. Die App und die über die App bereitgestellten
              Inhalte dürfen daher vom Nutzer nicht verändert, erweitert,
              bearbeitet, kopiert und/oder anderweitig verbreitet werden.
            </>,
            <>
              Vom App erzeugte Empfehlungen dürfen vom Nutzer für die
              Unterstützung eigener Dienstleistungen gegenüber den Kunden des
              Nutzers verwendet werden. WITTUR übernimmt keinerlei Verantwortung
              oder Haftung für diese Dienstleistungen und jegliche Handlungen
              des Nutzers oder dessen Kunden, die sich daraus ergeben.
            </>,
            <>
              Sofern in diesen Nutzungsbedingungen nichts anderes vorgesehen
              ist, verpflichtet sich der Nutzer, die App oder sonstige
              Bestandteile derselben nicht zu vervielfältigen, zu verbreiten, zu
              verändern oder abgeleitete Werke daraus zu erstellen und weder
              Reverse Engineering noch eine Dekompilierung der App vorzunehmen,
              es sei denn, dies ist nach zwingendem Recht erlaubt.
            </>,
            <>
              Der Nutzer erkennt an und stimmt zu, dass WITTUR berechtigt ist,
              die über die App verarbeiteten Daten in anonymisierter Form (im
              Sinne der anwendbaren Datenschutzgesetze) für beliebige
              Geschäftszwecke von WITTUR zu nutzen, insbesondere zu
              vervielfältigen und Dritten den Zugang zu solchen Daten zu
              gewähren. WITTUR wird die Daten nicht deanonymisieren und ist
              verpflichtet, alle geeigneten Maßnahmen zu ergreifen, um eine
              Deanonymisierung der Daten zu verhindern. Wenn Dritten Zugang zu
              den Daten gewährt wird, ist WITTUR verpflichtet, diese Dritten zur
              Unterlassung der Deanonymisierung und zur Ergreifung aller
              geeigneten Maßnahmen zur Verhinderung einer Deanonymisierung zu
              verpflichten. Soweit erforderlich, gewährt der Nutzer WITTUR eine
              unwiderrufliche, weltweite, nicht-exklusive, lizenzfreie,
              unterlizenzierbare Lizenz zur Nutzung dieser Daten. Die
              Geschäftszwecke umfassen unter anderem die Entwicklung,
              Herstellung, Verbesserung und/oder Vermarktung von Produkten und
              Dienstleistungen.
            </>,
          ],
        },
        {
          title: 'VIII. Laufzeit und Beendigung',
          content: [
            <>
              Der Vertrag hat eine unbefristete Laufzeit und der Nutzer ist
              berechtigt, den Vertrag jederzeit ohne Angabe von Gründen zu
              kündigen. Der Nutzer hat zur Kenntnis genommen und ist sich
              bewusst, (i) dass die Funktionalität der App eingeschränkt oder
              ausgesetzt wird, sobald die Abonnementlaufzeit und/oder
              Nutzungsrechte für die ElevatorSense Box gemäß dem Box-Vertrag
              enden, und (ii) dass die Kündigung des Vertrags durch den Nutzer
              aus Bequemlichkeitsgründen seine Verpflichtungen, die sich noch
              aus dem Box-Vertrag ergeben können, nicht berührt.
            </>,
            <>
              Sofern mit dem Nutzer nichts anderes vereinbart ist, gelten
              Lizenzen für vom Nutzer erworbene Features bis zum Ende der
              jeweiligen Laufzeit dieses Features. Wenn der Nutzer die Lizenz
              für ein Feature nicht drei (3) Monate vor Ablauf der Laufzeit
              kündigt, verlängert sich die Laufzeit automatisch um zwölf (12)
              Monate, und der Nutzer ist verpflichtet, zusätzliche, für diesen
              Zeitraum vereinbarte Entgelte zu zahlen. Das Recht, eine Lizenz
              für ein Feature aus wichtigem Grund mit sofortiger Wirkung zu
              kündigen, bleibt unberührt. Abschnitt VIII.1 gilt entsprechend für
              Features.
            </>,
            <>
              Mit Beendigung des Vertrags hat der Nutzer (i) die Nutzung der App
              unverzüglich einzustellen und erkennt an, dass WITTUR den Zugang
              des Nutzers und seines Autorisierten Personals zur App sperren
              kann, und (ii) unverzüglich, spätestens jedoch innerhalb von 5
              (fünf) Tagen, alle heruntergeladenen Inhalte und gedruckten
              Materialien zu löschen oder zu vernichten. Der Nutzer hat
              keinerlei Rechte, Ansprüche oder Interessen (und keine Urheber-,
              Marken- oder sonstigen geistigen Eigentumsrechte) an der App oder
              an irgendwelchen Inhalten, Informationen, Materialien, Anwendungen
              oder sonstigen damit verbundenen Funktionen.
            </>,
          ],
        },
        {
          title: 'IX. Rechte bei Mängeln',
          content: [
            <>
              Die App wird in einem für die vertragsgemäße Nutzung geeigneten
              Zustand bereitgestellt und gewartet. Die Wartungsverpflichtung
              umfasst nicht die Anpassung der Funktionalitäten der App selbst an
              geänderte Betriebsbedingungen sowie technische und funktionale
              Entwicklungen, die nicht mit der ElevatorSense Box zusammenhängen,
              die Anpassung an den Funktionsumfang konkurrierender Produkte oder
              die Herstellung der Kompatibilität mit neuen Browserversionen.
            </>,
            {
              text: (
                <>
                  WITTUR gewährleistet, dass die App frei von Rechten Dritter,
                  insbesondere von Rechten an geistigem Eigentum, ist, die ihre
                  vertragsgemäße Nutzung einschränken oder ausschließen. Im
                  Falle von Behauptungen, dass die vertragsgemäße Nutzung der
                  App gegen Patente, Urheberrechte, Marken oder andere geistige
                  Eigentumsrechte Dritter verstößt oder diese verletzt (
                  <strong>"Verstoß"</strong>)
                </>
              ),
              list: [
                <>
                  ist WITTUR berechtigt, entweder die App so zu modifizieren,
                  dass der Verstoß entfällt, oder eine Genehmigung für die
                  vertragsgemäße Nutzung der App einzuholen, ohne
                  Einschränkungen und ohne zusätzliche Kosten für den Nutzer;
                </>,
                <>
                  der Nutzer verpflichtet sich, WITTUR unverzüglich in Textform
                  (z. B. per E-Mail) über den Verstoß zu informieren und alle
                  damit zusammenhängenden Informationen bereitzustellen; und
                </>,
                <>
                  der Nutzer wird mit WITTUR kooperieren und diese bei der
                  Beendigung des Verstoßes unterstützen, soweit dies
                  vernünftigerweise von ihm erwartet werden kann.
                </>,
              ],
            },
            'Das Recht auf Schadensersatz unterliegt den Haftungsbeschränkungen gemäß dem folgenden Abschnitt X..',
          ],
        },
        {
          title: 'X. Haftungsbeschränkung',
          content: [
            'WITTUR haftet uneingeschränkt für Schäden, die durch vorsätzliche oder grob fahrlässige Handlungen von WITTUR oder ihren Erfüllungsgehilfen, für Schäden durch Verletzung von Leben, Körper oder Gesundheit sowie im Rahmen der anwendbaren Produkthaftungsgesetze verursacht werden.',
            <>
              Für Schäden und Aufwendungen, die auf einfacher Fahrlässigkeit
              beruhen, haftet WITTUR nur für typischerweise vorhersehbare
              Schäden, wenn diese Schäden und Aufwendungen durch die Verletzung
              wesentlicher Vertragspflichten durch WITTUR verursacht wurden.
              Vertragspflichten sind wesentlich, wenn ihre Erfüllung zur
              Erreichung des Vertragszwecks erforderlich ist und der Nutzer auf
              deren Erfüllung vertrauen darf.
            </>,
            'Im Übrigen haftet WITTUR nicht.',
            <>
              Zur Klarstellung: WITTUR haftet in keinem Fall für Schäden oder
              Verluste, soweit solche Schäden oder Verluste darauf beruhen, dass
              der Nutzer Empfehlungen oder Hinweise, die von oder in der App
              bereitgestellt werden, nicht befolgt.
            </>,
          ],
        },
        {
          title: 'XI. Haftungsbeschränkungen für bereitgestellte Inhalte',
          content: [
            <>
              Für im Rahmen der Services bereitgestellte Informationen und
              Empfehlungen, die in der App (
              <strong>"Bereitgestellte Inhalte"</strong>) bereitgestellt werden,
              übernimmt WITTUR keine Haftung. Wie sich aus Abschnitt VII.2
              ergibt, haftet WITTUR nicht für die Nutzung der Bereitgestellten
              Inhalte durch oder für die eigenen Kunden des Nutzers.
            </>,
            <>
              Bei der Bereitstellung von Informationen in den Services können
              die Informationen ungenau sein, da die von der ElevatorSense Box
              generierten Daten äußeren Einflüssen unterliegen.
            </>,
            <>
              Bei der Bereitstellung von Empfehlungen stellt die App bestimmte
              Wahrscheinlichkeiten für ein bestimmtes Ergebnis bereit und
              empfiehlt entsprechende Maßnahmen. Die App gibt jedoch keine
              abschließende Beurteilung ab. Die Durchführung einer sorgfältigen
              Beurteilung, einschließlich des Kaufs von ElevatorSense-Produkten
              im WITTUR-Webshop als Ersatz, liegt in der alleinigen und
              ausschließlichen Verantwortung des Nutzers.
            </>,
            {
              text: 'Der Nutzer sollte die folgenden Einschränkungen beachten, die für die von dieser App generierten Bereitgestellten Inhalte gelten:',
              list: [
                <>
                  Die Bereitgestellten Inhalte werden mithilfe hochkomplexer
                  Algorithmen generiert. Es ist jedoch offensichtlich unmöglich,
                  dass ein Algorithmus jede einzelne Besonderheit eines
                  individuellen Ereignisses abbildet. Die App liefert daher eine
                  kontinuierlich verbesserte Annäherung zur individuellen
                  Entscheidungsunterstützung.
                </>,
                <>
                  Neue und aktualisierte Daten, die die Grundlage für die
                  Bereitgestellten Inhalte bilden, werden ständig in den
                  Algorithmus eingespeist. WITTUR stellt diese Daten nach bestem
                  Wissen und unter professioneller Sorgfalt zusammen. Es kann
                  jedoch keine Garantie dafür übernommen werden, dass die Daten
                  korrekt, vollständig und aktuell sind. Fehler in einem
                  Datensatz könnten zu unzuverlässigen Bereitgestellten Inhalten
                  führen.
                </>,
              ],
            },
          ],
        },
        {
          title: 'XII. Datenschutz',
          content: [
            <>
              Da bei WITTUR der Datenschutz oberste Priorität hat, beachtet
              WITTUR bei der Verarbeitung (z. B. Erhebung, Nutzung, Offenlegung
              etc.) personenbezogener Daten strikt die geltenden
              Datenschutzgesetze. Sofern und soweit nicht anders über die
              spezifische Verarbeitung personenbezogener Daten durch WITTUR
              informiert wird, sind Informationen über eine solche Verarbeitung
              in der jeweils geltenden Datenschutzerklärung enthalten, die in
              der App zugänglich ist.
            </>,
            'Der Nutzer hat die geltenden Datenschutzgesetze einzuhalten, wenn er WITTUR personenbezogene Daten zur Verfügung stellt, z. B. über die App.',
          ],
        },
        {
          title: 'XIII. Änderungen / Übertragung des Vertrags',
          content: [
            <>
              WITTUR ist berechtigt, diese Nutzungsbedingungen jederzeit zu
              ändern. Der Nutzer wird über solche Änderungen mindestens sechzig
              (60) Kalendertage vor der geplanten Umsetzung der Änderungen
              informiert. Widerspricht der Nutzer nicht innerhalb von dreißig
              (30) Kalendertagen nach Erhalt der Mitteilung und nutzt die App
              nach Ablauf der Widerspruchsfrist weiter, so gelten die Änderungen
              ab Ablauf der Widerspruchsfrist als wirksam vereinbart. In dieser
              Mitteilung wird der Nutzer über sein Widerspruchsrecht und dessen
              Folgen informiert.
            </>,
            <>
              WITTUR behält sich das Recht vor, diesen Vertrag mit dem Nutzer an
              ein anderes Unternehmen zu übertragen. Die Nutzer werden über eine
              solche Übertragung informiert und können innerhalb von zehn (10)
              Kalendertagen nach Erhalt der Information über die Übertragung vom
              Vertrag zurücktreten.
            </>,
          ],
        },
        {
          title: 'XIV. Sonstiges',
          content: [
            <>
              Sollten eine oder mehrere Bestimmungen dieser Nutzungsbedingungen
              aufgrund eines Verstoßes gegen geltendes Recht oder aus anderen
              Gründen unwirksam oder undurchsetzbar sein, so bleiben die übrigen
              Bestimmungen dieser Nutzungsbedingungen davon unberührt und
              weiterhin gültig. Die unwirksame oder undurchsetzbare Bestimmung
              wird durch eine wirksame und durchsetzbare Bestimmung ersetzt,
              welche dem gemeinsamen Verständnis der Parteien am nächsten kommt.
            </>,
            <>
              Für die Gültigkeit, Auslegung und Durchführung dieser
              Nutzungsbedingungen gilt deutsches Recht unter Ausschluss der
              kollisionsrechtlichen Bestimmungen. Für alle Streitigkeiten, die
              sich aus oder im Zusammenhang mit diesen Nutzungsbedingungen
              ergeben, sind die Gerichte in München zuständig.
            </>,
          ],
        },
        {
          title: 'XV. Internationale Nutzer',
          content: [
            <>
              Diese App wird von der WITTUR GmbH betrieben, überwacht und
              aktualisiert. Sie ist ausschließlich für die Nutzung in den in
              <a href="#appendix_1">Anhang 1</a> aufgeführten Ländern
              vorgesehen. Sollte der Nutzer auf diese App von außerhalb dieser
              Länder zugreifen und/oder Inhalte herunterladen, trägt der Nutzer
              allein und ausschließlich die Verantwortung dafür, dass eine
              solche Nutzung den einschlägigen lokalen Gesetzen entspricht.
            </>,
          ],
        },
      ],
    },
    appendix: {
      title: 'Anhang 1: VERTRIEBSLAND UND LOKALE RECHTSBEDINGUNGEN',
      content: [
        {
          heading:
            'Zusätzliche oder Abweichende Bedingungen für das Vertriebsland',
          details: [
            {
              country: 'Indien',
              content: [
                'Abschnitt V. (Benutzerkonto) wird wie folgt ergänzt:',
                <>
                  Der Nutzer stimmt zu, dass WITTUR oder deren Affiliates sein
                  Passwort erfassen, um den Zugriff auf und die Nutzung der App
                  zu ermöglichen. Das Passwort kann von WITTUR oder deren
                  Affiliates gemäß der Datenschutzrichtlinie verarbeitet werden.
                </>,
                <>
                  Abschnitt X.2. (Haftungsbeschränkung) wird wie folgt geändert:
                  Für Schäden und Aufwendungen, die durch einen wesentlichen
                  Verstoß von WITTUR verursacht wurden, haftet WITTUR nur für
                  Schäden, die im normalen Verlauf eines solchen Verstoßes
                  typischerweise entstehen, oder von denen der Nutzer zum
                  Zeitpunkt der Zustimmung zu den Nutzungsbedingungen wusste,
                  dass sie wahrscheinlich aus dem Verstoß resultieren würden.
                  (i) Hierunter fallen nicht solche Schäden, die aus entfernten
                  und indirekten Verlusten oder Schäden aufgrund des
                  wesentlichen Verstoßes entstehen, und (ii) die Haftung ist auf
                  die Vergütung beschränkt, die WITTUR vom Nutzer für die
                  ElevatorSense Lösung erhalten hat. Der Nutzer und WITTUR
                  stimmen zu, dass dies eine faire, angemessene und tatsächliche
                  Vorabschätzung für etwaige Schäden ist, die aus einem
                  wesentlichen Verstoß entstehen.
                </>,
              ],
            },
            {
              country: 'Vereinigtes Königreich',
              content: [
                <>
                  Abschnitt X.2. (Haftungsbeschränkung) wird wie folgt geändert:
                  <br /> WITTUR haftet nur für typischerweise vorhersehbare
                  Schäden und Aufwendungen, wenn diese Schäden und Aufwendungen
                  durch einen wesentlichen Verstoß von WITTUR verursacht wurden,
                  der nicht innerhalb von dreißig (30) Tagen nach Erhalt einer
                  entsprechenden Benachrichtigung des Verstoßes durch den Kunden
                  behoben wird. Vorbehaltlich des Vorstehenden schließt WITTUR
                  jegliche weitere Haftung (ob direkt oder indirekt und
                  unabhängig von der Ursache) im Rahmen dieser
                  Nutzungsbedingungen aus.
                </>,
              ],
            },
          ],
        },
      ],
    },
  },
  testCompleted: 'Test abgeschlossen.',
  testResults: 'Testergebnis',
  time: 'Uhrzeit',
  tooHigh: 'Zu hoch',
  tooLoose: 'Zu locker',
  tooLow: 'Zu gering',
  tooTight: 'Zu fest',
  top: 'oben',
  topToBottom: 'Von oben nach unten',
  touching: 'streifend',
  transformer: 'Transformator',
  truckRoller: 'Laufrolle',
  truckRollerInferior: 'Laufrolle minderwertig',
  truckRollerSuperior: 'Laufrolle superior',
  twisted_cd_panels: 'Verdrehte Kabinenpanele',
  twisted_ld_panels: 'Verdrehte Schachtpanele',
  unlinkBox: 'Box trennen',
  unreadNotifications: (n: number) =>
    `${n} ungelesene Benachrichtigung${n === 1 ? '' : 'en'}`,
  unshare: 'Freigabe aufheben',
  update: 'Aktualisieren',
  updateRequired: 'Aktualisierung erforderlich',
  updateStaticData: 'Daten aktualisieren',
  updating: 'Aktualisiert......',
  upToDate: 'Aktuell',
  usage: 'Bisherige Nutzung',
  usedElectronics: 'Genutzte Aufzüge',
  validFrom: 'Gültig von',
  validUntil: 'Gültig bis',
  vibration: 'Vibration',
  viewErrorLog: 'Fehlerprotokoll anzeigen',
  waitWhileInitializingTest: 'Der Test wird initialisiert. Bitte warten ...',
  waitingForData: 'Warten auf neue Daten',
  waitingForTestResults: 'Warten auf das Testergebnis ...',
  warning: 'Warnung',
  warnings: 'Warnungen',
  warrantyExtensionRequestedOn: (date: string) =>
    `Garantieverlängerung wurde am ${date} beantragt`,
  whyNotReplaced: 'Warum wurde die Komponente nicht ersetzt?',
  wifiScanned: 'WIFI wurde gescannt',
  years: 'Jahre',
  yes: 'Ja',
  zero_position: 'Nullposition',
  zero_position_description:
    'Die Nullposition des Türantriebs wurde falsch angelernt.',
  zero_position_eco_actions:
    '1. S1DIP1 = EIN (Service mode)\n2. schieben sie die Türe per Hand zu (Nullposition)\n3. drücken sie den Lerntaster >10s (Longlearn)\n4. Öffnen und Schließen sie die Türe per Servicedrive Taster\n5. halten sie die Taster in den Enden länger gedrückt\n6. Nach 2 Zyklen muss die State LED erlöschen',
  zero_position_midi_actions:
    '1. schieben sie die Türe per Hand zu (Nullposition)\n2. Piktogramm befolgen:',
  yourBuilding: 'Ihr Gebäude',
  yourBuildings: 'Ihre Gebäude',
};
