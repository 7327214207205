import React from 'react';

export const en = {
  aborted: 'Aborted',
  accelerationDescription:
    'Acceleration factor which is used for curve calculation.',
  accelerationNote:
    'This factor has to be high enought to reach the adjusted speeds, otherwise the door will not reach the wanted speed.',
  accelerationTitle: 'Acceleration',
  acceptTerms: 'I accept the terms of usage',
  add: 'Add',
  addBuilding: 'Add Building',
  addLift: 'Add Elevator',
  addressHint:
    'Enter a company or building name to get address and image suggestions.',
  adjustCameraSettings:
    'You can also adjust the camera settings using the dropdown menu.',
  admin: 'Admin',
  alreadyHasSubcontractorsError:
    'As a subcontractor, you cannot have subcontractors. Please remove existing subcontractors before accepting this invitation.',
  all: 'All',
  allBuildings: 'All Buildings',
  allBuildingsSubscription: 'From all buildings',
  alphabetical: 'Alphabetical',
  ambientTemperature: 'Box Temperature',
  assetNumber: 'Elevator ID',
  assetNumberHint:
    'Please enter the ID (aka facility/equipment number) of the elevator if you have it to hand.',
  auto: 'Auto',
  back: 'Back',
  backToDashboard: 'Back to Dashboard',
  belt_tension: 'Belt Tension',
  belt_tension_actions:
    'Adjust the belt tension as described in the installation manual.',
  beltclamp: 'Beltclamp',
  belt_tension_description: 'The Belt tension is too high/low.',
  betweenDoorCycle: 'Between Door Cycle',
  bottom: 'bottom',
  bottomToTop: 'Bottom to Top',
  boxLinkedToOtherLift: (lift: string, building: string) =>
    `The box is linked to ${lift} in ${building}. Continue?`,
  boxNotReady: 'The selected box is not ready.',
  boxOfflineHint:
    'If you want to update one of the boxes listed below, please connect it to the internet first.',
  boxScannedNoIdFound: 'Box scanned, but no ID found.',
  boxSoftware: 'Software',
  boxSoftwareStatusError:
    'The status of the boxes could not be retrieved: please check the Internet connection of the box and try again. If the problem persists, please contact your Wittur representative.',
  boxSoftwareTitle: 'Box Software',
  boxSoftwareUpdateError:
    'Failed to update box software: please check the internet connection of the box and try again. If the problem persists, contact your Wittur representative.',
  box_id: 'Box ID',
  brakeParDescription:
    'Because of the adjustment of this parameter the delay and creep to the open / close end is influenced (pre‐adjusted at ‘5’).',
  brakeParNote:
    'The possible adjustment is ‘0’..’9’, but the earliest brake is reached with parameter ‘0’ and the latest delay is reached with parameter ‘9’.',
  brakeParTitle: 'Break Parameter',
  buffer: 'Buffer',
  building: 'Building',
  buildingHasNoElevator: 'This building has no elevators.',
  buildingName: 'Name',
  buildings: 'Buildings',
  buildingsWithoutValidAddress: 'Buildings without valid address',
  bushingClose: 'Bushing Close',
  bushingOpen: 'Bushing Open',
  cablechain: 'Cablechain',
  cableTensorSpring: 'Cable tensor spring',
  callFloorsInstruction: (
    <>
      Open the door and <b>call all other floors</b>. Step outside the car and
      click "Continue".
    </>
  ),
  cameraMode: 'Camera mode',
  cancel: 'Cancel',
  car: 'Car',
  carDoor: 'Car Door',
  carDoorCounter: 'Door Cycle Counter of previous Door Electronics',
  carDoorModified: 'Car door has been modified',
  cd_counter_rollers_installation: 'Counter Rollers',
  cd_counter_rollers_installation_actions:
    'For each landing door, check that the rollers can be turned easily by hand and that their distance from the rail is not too big. If this happens,  loosen the rollers and re-adjust them so that they are as close as possible to the rail, but do not touch it, and can still be turned easily by hand.\n\n  If you need to dismount the coupler to access the rollers, follow the installation instructions.',
  cd_counter_rollers_installation_description:
    'CD counter rollers are not installed correctly.',
  cd_roller: 'CD Roller',
  chooseFloorOrCardoor: 'Choose Floor / Cardoor',
  choosePartNumber: 'Choose Part Number',
  chooseReason: 'Choose a reason (optional)',
  chooseStartingFloorInstructions:
    'Please select the starting floor of the control run. The starting floor can either be the top or the bottom floor.',
  clearDriveErrors: 'Clear',
  clearOpening: 'Clear Opening',
  close: 'Close',
  closeDoorInstructions: (floor: string) => (
    <>
      Head to to the <b>{floor}</b> floor. Make sure the car door is closed
      before proceeding.
    </>
  ),
  closeForcePotiDescription:
    'Set force limit used in opening and closing of the door.',
  closeForcePotiNote:
    'Adjustable via potentiometer on the electronic. On newer electronics like MIDIV2 set able here in Service drive Mode if needed (no need to adjust in standard applications due to self-learning).',
  closeForcePotiTitle: 'Close force limit',
  closeHoldForceDescription: 'Force which is applied at close end.',
  closeHoldForceNote:
    'This force should be between 50N and 70N. Be aware too low force will cause not wanted coupler openings. Too high force will heat up the motor uneccessary and may it will operate in reduction mode.',
  closeHoldForceTitle: 'Close End (Hold) Force',
  closeSpeedDescription:
    'Maximal door speed in closing direction. The close curve is calculated to reach this adjusted speed if possible.',
  closeSpeedNote:
    'The real door speed is also related to the drives Speed DIP switches. Adjustment to speed 4 means 100% of Value 2.',
  closeSpeedTitle: 'Close Speed',
  closing_device_failure: 'Closing Device',
  closing_device_failure_actions:
    'Check that the closing device for the landing door  works correctly in all its components (spirator, closing spring and closing weight).\n    The landing door must be able to close by itself from every door position.',
  closing_device_failure_description:
    'The Closing device is not installed correctly. The force required to close the door is too high.',
  companyName: 'Company name',
  completed: 'Completed',
  componentDetails: 'Component Details',
  componentDetailsHint:
    'For some components, there are multiple part number options that must be selected. This is important information for using the preventive maintenance function. Please click on the listed items to select the actual part installed.',
  confirm: 'Confirm',
  confirmDeleteBuilding: 'Do you really want to delete this building?',
  confirmDeleteErrorHistory: 'Do you really want to delete this error history?',
  confirmDeleteFavoriteBuilding:
    'Do you really want to remove this building from your favorites?',
  confirmDeleteLift: 'Do you really want to delete this elevator?',
  confirmInvite: 'Confirm and Connect',
  connectionErrorMessage:
    'Unable to fetch data due to a connection issue. Please try again later.',
  continue: 'Continue',
  contractorBuilding: 'Contractor Building',
  contractorBuildings: 'Contractor Buildings',
  contractors: 'Contractors',
  couplerelease: 'Couplerrelease',
  couplerFriction: 'Coupler Friction',
  couplerMovementParameter: 'Coupler Movement Parameter',
  couplerSpeedDescription:
    'This value defines the maximum speed of the belt, in the defined coupler area.',
  couplerSpeedTitle: 'Coupler Speed',
  couplerWitdhDescription:
    'This value defines the belt distance in the coupler area. This value is resulting after the learn procedure.',
  couplerWitdhTitle: 'Coupler Width',
  currentVersion: 'Current Version',
  couplerwidth: 'Coupler Width',
  date: 'Date',
  delete: 'Delete',
  deleteErrorHistory: 'Delete error history',
  directConnection: (
    <>
      <p>
        Connect to the WIFI of the ELEVATORSENSE box by scanning the QR code
        printed on the box with your phone's camera app.
      </p>
      <p>
        Once connected go back to this screen an hit the <b>continue</b> button
        below.
      </p>
    </>
  ),
  disconnectProgrammingTool: 'Disconnect Wireless Programming',
  divpulley: 'Divpulley',
  documentation: 'Documentation',
  doorContact: 'Door contact',
  doorCycleCounter: 'Door Cycle Counter',
  doorCycleError1: 'No door cycle data was received.',
  doorCycleError2: 'The door cycle movement was disrupted.',
  doorCycleError4: 'A re-opening of the door was detected.',
  doorCycleError5: 'A re-closing of the door was detected.',
  doorCycleError7: 'No valid door cycles were received.',
  doorCycles: 'Door cycles',
  doorFriction: 'Door Friction',
  doorInstallationDate: 'Door Installation Date',
  doorIsClosed: 'Yes, the door is closed',
  doorModel: 'Door Model',
  doorModelNotSupported:
    'The selected door model is not available for preventive maintenance.',
  doorMovementParameter: 'Door Movement Parameter',
  doorPosition: 'Door Position',
  doorSerialNumber: 'Door Serial Number',
  doorType: 'Door Type',
  doorwidth: 'Door Width',
  downloadCertificate: 'Download certificate',
  downloadLicense: 'Download all licenses as CSV',
  downloadRequestedExtendedWarranty:
    'Download all requests for warranty extension',
  drive_motor_noise: 'Drive Motor Noise',
  driveesn: 'ESN',
  drivesoftware: 'Firmware',
  duringLiftMovement: 'During Lift Movement',
  editBuilding: 'Edit Building',
  editLift: 'Edit Elevator',
  elecBoard: 'Elec board',
  elevatorIdNotFound: (
    <>
      To request an extended warranty, you must enter the door serial number in
      the elevator settings. <b>Click here to go to the settings.</b>
    </>
  ),
  elevatorInstallationDate: 'Elevator Installation Date',
  elevatorUsageType: 'Elevator Usage Type',
  email: 'Email',
  encoderTemperature: 'Encoder Temperature',
  error: 'Error',
  errorDescription: 'Error Description',
  errorEarlierThanInstallationDate:
    'It is not possible to enter a date earlier than the installation date.',
  errorInvalid: 'Please enter a valid value.',
  errorInvalidOperation: 'Invalid operation',
  errorMessages: 'Error Messages',
  errorMessagesDescription: 'Display of logged error messages.',
  errorNotFound: 'Resource not found',
  errorRetryCamera:
    'Unable to access the camera. Retrying, the page will reload...',
  errorRequired: 'This field is required.',
  errorUnableToAccessCamera:
    'Unable to access the camera after multiple attempts. Please refresh the page or check camera permissions.',
  errorUnknownOrForbiddenBox:
    'The Box is not available. Please contact your Wittur service if you need support.',
  errors: 'Errors',
  errorsEmpty: 'No messages.',
  errorsEmptyHint:
    'Please check your filters and maybe enable addtional types.',
  extendWarranty: 'Extend Warranty',
  fatalError: 'Fatal Error',
  fatalErrors: 'Fatal Errors',
  favorite: 'Favorite',
  favorites: 'Favorites',
  figureAdjustment: 'Adjustment',
  figureAugustaEVO: 'Example Augusta EVO',
  figureClosingWeight: 'Closing weight',
  figureEco: 'ECO+',
  figureHydra: 'Example Hydra',
  figureMidi: 'MIDI/SUPRAV2',
  figureNotOk: 'Not OK',
  figureOk: 'OK',
  figureSGV: 'Example SGV',
  figureSpirator: 'Spirator',
  figureSpring: 'Closing spring',
  firstName: 'First name',
  floor: 'Floor',
  floors: 'Floors',
  followingBoxScanned: 'Following box is scanned',
  forgotPassword: 'Forgot your password?',
  forgotPasswordText:
    'Enter your email address in order to reset your password.',
  groundFloor: 'Ground Floor',
  home: 'Home',
  incompleteElevatorSettings:
    'In order to use preventive maintenance, we need more information about this elevator. Please go to your elevator settings and fill in the missing information.',
  initialized: 'Initialized',
  installationControl: 'Installation Control',
  installationControlDescription:
    'Perform automatic checks to detect common installation issues.',
  installationControlExecutedBy: 'Executed by',
  installationControlHeadline: 'Latest Installation Status',
  installationControlHistory: 'Run History',
  installationControlHistoryEmpty: 'No recorded installation runs',
  installationControlInstructions: 'Show Instructions',
  installationControlSubheadline: 'Summary of all runs',
  installationDateErrorMessage:
    'It is not possible to enter an earlier door installation date than the elevator installation date',
  instantSubscription: 'Instant notification',
  instantSubscriptionExplanation: (
    <>
      <b>Instant notification</b> is an immediate notification by e-mail as soon
      as a fatal error has occurred on an elevator. Fatal errors are errors that
      can lead to the immediate failure of the elevator.
    </>
  ),
  invitationExpired: 'The invitation link is no longer valid.',
  inviteAsManager: 'User may invite other users.',
  inviteCompanyWithSubcontractorsError:
    'The company you are trying to invite already has subcontractors and cannot be added as a subcontractor. They have to remove existing subcontractors or choose another company.',
  inviteCoworkers: 'Invite coworkers',
  inviteSubcontractor: 'Invite a Subcontractor',
  inviteToBeSubcontractorMessage: (companyName: string) =>
    `${companyName} has invited you to be their subcontractor in ElevatorSense.`,
  inviteToSignUpMessage: (companyName: string) =>
    `The company ${companyName} has invited you to sign up for ElevatorSense as their subcontractor. Please complete the form to register.`,
  ipaddress: 'IP address',
  joinText: (name: string) => (
    <>
      Please sign up to join the <i>{name}</i> organization.
    </>
  ),
  landingDoor: 'Landing Door - Floor',
  landing_lock_roller_adjustment: 'Landing Lock Roller Adjustment',
  landingCarDoorSpareParts: 'Landing/Car Door Spare Parts',
  lastError: 'Last Error',
  lastExchangeDate: 'Last Date of Exchange',
  lastMaintenanceDate: 'Last Maintenance Date',
  lastMaintenanceScope: 'Last Maintenance Scope',
  lastMaintenanceScopeHint:
    'Select the maintenance scope recommended by Wittur that was actually performed on the last maintenance date.',
  lastName: 'Last name',
  lastTimeDoorElectronicsChange: 'Date of Door Electronics Replacement',
  latestSoftwarePackVersion: (version: string) =>
    `Software pack version: ${version}`,
  ld_counter_rollers_installation: 'Counter Rollers',
  ld_counter_rollers_installation_actions:
    'For each landing door, check that the rollers can be turned easily by hand and that their distance from the rail is not too big. If this happens,  loosen the rollers and re-adjust them so that they are as close as possible to the rail, but do not touch it, and can still be turned easily by hand.\n\n  If you need to dismount the coupler to access the rollers, follow the installation instructions.',
  ld_counter_rollers_installation_description:
    'LD counter rollers are not installed correctly.',
  ld_roller: 'LD Roller',
  left: 'Left',
  licenseRequired:
    "You haven't purchased a valid license for this product. Please contact your Wittur sales person to buy this ElevatorSense product.",
  lift: 'Elevator',
  liftMovementDistance: 'Lift movement',
  liftSetup: 'Lift Setup',
  liftSetupComplete: 'Setup completed.',
  liftSetupError: 'The corresponding data is incomplete or missing.',
  liftSetupError40: 'The elevator movement does not match the setup run.',
  liftSetupIncomplete: 'Setup incomplete.',
  liftSetupPending:
    'Please perform the setup run for initial setup of ElevatorSense on your elevator. Without the setup run you will not be able to use some functionalities of the ElevatorSense products or the results of the ElevatorSense products may differ. Click here to perform the setup run.',
  liftSetupRepeat:
    'The setup run was not successful. Please perform the run again, taking into account the process description.',
  lifts: 'Elevators',
  linkBox: 'Link Box',
  linkToWebshop: (link: string) => (
    <>
      If you have an ElevatorSense box and you would like to activate a license
      please click{' '}
      <a href={link} className="clickable-link">
        here
      </a>
      .
    </>
  ),
  loading: 'Loading',
  login: 'Login',
  loginText: 'Please log in with your account.',
  logout: 'Logout',
  manageAccess: 'Manage Access',
  manageElevators: 'Manage Elevators',
  manageSharedBuildingsAndElevator: 'Manage Shared Buildings/Elevators',
  mandatory: 'Perform Replacement Check',
  manual: 'Manual',
  maxElectronics: 'Max elevators',
  maxUsage: 'Max Usage',
  measured: 'Measured',
  members: 'Members',
  misalignment_cd_vs_ld: 'Door/coupler Alignment',
  misalignment_cd_vs_ld_actions:
    'Adjust the landing door lock rollers. The running clearance between the coupler vanes and the rollers must be the same on the left and the right side.',
  misalignment_cd_vs_ld_description:
    'The Car Door coupler is misaligned with the Landing Door lock rollers.',
  misalignment_cd_vs_ld_sill_gap: 'Sill Gap',
  misalignment_cd_vs_ld_sill_gap_actions:
    'The sill gap on the left end and on the right end of the door opening is not equal. If this occurs on all landings, adjust the car door sill position.',
  misalignment_cd_vs_ld_sill_gap_description:
    'The landing door is not parallel to the car door.',
  mode: 'Mode',
  monitoring: 'Monitoring',
  motor: 'Motor',
  motorTemperature: 'Motor Temperature',
  myBuildingsSubscription: 'From my favorites only',
  name: 'Name',
  networkQuality: 'Network Quality',
  newInstallationControl: 'New Installation Control',
  next: 'Next',
  nextMaintenanceDate: 'Next maintenance date',
  no: 'No',
  notAuthorizedToConfirmInviteError:
    'You are not authorized to confirm this invitation as it is not intended for your logged-in user.',
  noBoxLinked:
    'The elevator is not yet linked to an ELEVATORSENSE box. In order to use all features of the app, please scan the QR code on the box or enter the box-id manually.',
  noBuildingsVisible:
    'No buildings are visible in the current map view. Try adjusting the map to see more buildings.',
  noComponentsFound: 'No components found for this floor',
  noFavorite: 'Not a favorite',
  noFavoritesChoosen:
    'You have not selected any favorite buildings yet. Navigate to your building and click the star icon to add it to your favorite buildings.',
  noFloorInfoAvailable: 'No floor information available',
  noInstallationControlFound:
    'The ESN of the door electronics does not match the ESN of the last Installation Control run. Please check if you are connected to the correct elevator.',
  noSubcontractors: 'No subcontractors found.',
  noSubcontractorMessage:
    'No subcontractors are currently linked to your company. To establish one, send an invitation.',
  noSubscription: 'None',
  note: 'Note',
  noQRCodeDetected:
    'No QR code detected yet. Please move the camera so that the QR code is in focus and fully visible in the view.',
  nudgingSpeedDescription:
    'Door speed in Nudging condition. The close/open curve is calculated to reach this adjusted speed if nudging is active.',
  nudgingSpeedNote:
    'The real door speed is also related to the drives Speed DIP switches. Adjustment to speed 4 means 100% of Value 3.',
  nudgingSpeedTitle: 'Nudging Speed',
  obsolete: 'Obsolete',
  offline: 'Offline',
  offlineConnection:
    'You are currently offline, but you can use the Programming tool.',
  ok: 'Regular Maintenance',
  online: 'En línea',
  openHoldForceDescription: 'Force which is applied at open end.',
  openHoldForceNote:
    'This force should be between 50N and 60N. Be aware too low force will cause not wanted closings and openings. Too high open end force will heat up the motor uneccessary and may it will operate in reduction mode.',
  openHoldForceTitle: 'Open End (Hold) Force',
  openSpeedDescription:
    'Maximal door speed in opening direction. The open curve is calculated to reach this adjusted speed if possible.',
  openSpeedNote:
    'The real door speed is also related to the drives Speed DIP switches. Adjustment to speed 4 means 100% of Value 1.',
  openSpeedTitle: 'Open Speed',
  otherReason: 'Other reason',
  overdue: 'Overdue',
  parameter: 'Parameter',
  partInformation: 'Part Information',
  partIsStillOk: 'Part is still OK',
  partNumber: 'Part Number',
  partName: 'Part Name',
  password: 'Password',
  passwordResetSent:
    "We've sent you an email with instructions on how to reset your password. Please check your inbox.",
  pendingInvites: 'Pending Invites',
  pendingUserInvites: 'Pending User Invitations',
  pendingSubcontractorInvites: 'Pending Subcontractor Invitations',
  planningAndAlerts: 'Planning and alerts',
  position: 'Position',
  positionOutDescription:
    'By entering this parameter the position relay (FPC related) can be adjusted to switch at any door position. Standard adjustment is 0 (deactivated).',
  positionOutNote:
    'In case of setting this parameter to another value, the relay is switching when reaching the possition. This function is almost only used in shafts with premoving cabins.',
  positionOutTitle: 'Position Out',
  postpone: 'Postpone',
  powerReductionFactor: 'Power Reduction Factor',
  predicted: 'Predicted',
  prev: 'Prev',
  preventiveMaintenance: 'Preventive Maintenance',
  preventiveMaintenanceDescription:
    'Replace your elevator door spare parts before they break.',
  preventiveMaintenanceUnavailable:
    'The entered data is complete! ElevatorSense will now calculate the maintenance schedule based on the usage of your elevator. This process may take a few minutes.',
  priority: 'Priority',
  product: 'Product',
  programmingDisabledHint:
    'Programming of the electronic is currently disabled by the hardware. Please make sure that the service drive mode is turned on to allow programming of the device.',
  programmingErrorConnection: (
    <>
      <p>
        We are discovering troubles with the connection to ELEVATORSENSE box.
      </p>
      <p>
        Please make sure to switch off your "mobile data connection" and ensure
        you are connected to the WIFI of the ELEVATORSENSE box only.
      </p>
      <p>
        Afterwards hit the <b>continue</b> button below.
      </p>
    </>
  ),
  programmingErrorNoPopup:
    "Failed to open window. Make sure popups aren't blocked by your browser.",
  programmingErrorRequest:
    'Request failed. Please make sure your device is connected to the WIFI network of the ELEVATORSENSE box.',
  programmingTool: 'Wireless Programming',
  programmingToolDescription: 'Easy configuration of door electronics.',
  pulley_is_touching_belt: 'Pulley',
  pulley_is_touching_belt_actions:
    'Adjust belt alignment so that the belt does not touch the pulley flange borders.',
  pulley_is_touching_belt_description:
    'The pulley flange is touching the belt.',
  readyToRunTest: 'Ready to run the test.',
  realtimeMonitoringDescription:
    'View sensor data and door motion graphs in real time.',
  realtimeMonitoringTitle: 'Real Time Diagnostics',
  recommended: 'Plan Replacement Check',
  recommendedActions: 'Recommended Actions',
  recommendedDateOfExchange: 'Recommended Date of Replacement Check',
  reload: 'Reload',
  remove: 'Remove',
  reopenTimeDescription:
    'The Reopen parameter defines the waiting time periode after the automatic reopen movement in open end position, before closing the door again.',
  reopenTimeNote:
    'This function is used in case of reopening procedure triggered by the door electronic an not by the main lift controller.',
  reopenTimeParameter: 'Reopen time parameter',
  reopenTimeTitle: 'Reopen Time',
  repeatFloor: 'Please repeat the installation control process for this floor!',
  repeatWholeRun: (
    <>
      Please repeat the <b>full</b> installation control process!
    </>
  ),
  replaced: 'Replaced',
  replacementWasPostponed: 'The replacement was postponed on:',
  requestNewLink: 'Request new link',
  resetPassword: 'Reset password',
  resetPasswordText: 'Please enter a new password.',
  revoke: 'Revoke',
  right: 'Right',
  rollerBumper: 'Roller Bumper',
  rollerSynchronisationRope: 'Roller synchronisation rope',
  runs: 'Runs',
  save: 'Save',
  scan: 'Scan',
  scanned: 'Scanned',
  scanAgain: 'Scan again',
  searchPlaces: 'Search places',
  select: 'Select',
  selectCameraMode: 'Select camera mode',
  selectClearOpening: 'Select Clear Opening',
  selectDoorModel: 'Select Door Model',
  selectDoorType: 'Select Door Type',
  selectModifiedFloors:
    'Please select the floors on which changes have been made since the last test run. The floors on which errors occurred during the last run are preselected.',
  selectPartsHint:
    'Please check which part is installed and click on the corresponding image.',
  send: 'Send',
  sendResults: 'Send Results',
  sendTo: 'Send results to the following email addresses:',
  sensorData: 'Sensor Data',
  serviceDriveSwitch: 'Service drive switch',
  settings: 'Settings',
  share: 'Share',
  shareBody: (link: string) => `View test result:\n${link}`,
  shareResults: 'Share Results',
  shareSubject: 'ELEVATORSENSE Result',
  shoe: 'Shoe',
  showPassedTests: 'Show passed tests ...',
  showTerms: 'Show terms of usage',
  signUp: 'Sign up',
  skid: 'Skid',
  slideingshoe_failure: 'Slideingshoe Failure',
  slideingshoes_usage: 'Slideingshoes Usage',
  somethingWentWrong: 'Something went wrong. Please try again.',
  sortBy: 'Sort By',
  sortByDate: 'Date',
  sortByFloor: 'Floor Number',
  sparePartDoesNotFit: 'Spare part does not fit',
  sparePartNotAvailable: 'Spare part not available',
  speed: 'Speed',
  start: 'Start',
  startNewTestRun: 'New Test Run',
  started: 'Running',
  starting_floor: 'Starting Floor',
  status: 'Status',
  subcontractorNotFound: 'Subcontractor not found',
  subcontractors: 'Subcontractors',
  subcontractorManagement: 'Subcontractor Management',
  subscriptionHint:
    'You will receive email notifications only if you have purchased appropriate products for your ElevatorSense Box.',
  subscriptionText:
    'Please choose the email notifications you would like to receive.',
  subscriptions: 'Notifications',
  summarySubscription: 'Daily report',
  summarySubscriptionExplanation: (
    <>
      The <b>daily report</b> includes an email with an overview of all selected
      buildings and elevators with an indication of the number of errors.
    </>
  ),
  temperature: 'Temperature',
  termsOfUse: {
    general: {
      title: 'GENERAL TERMS AND CONDITIONS OF USE OF ELEVATORSENSE APPLICATION',
      sections: [
        {
          title: 'I. General',
          content: [
            <>
              Wittur GmbH (hereinafter <strong>"WITTUR"</strong>,
              <strong>"We"</strong>) is offering users (<strong>"User"</strong>
              ), either itself or through Affiliates as further defined
              hereinafter, a solution consisting of hardware collecting data
              from passenger and freight elevators in buildings or components
              thereof (the <strong>"ElevatorSense Box"</strong>), and the
              ElevatorSense mobile and web application (hereinafter
              <strong>"Mobile App"</strong>, <strong>"Web App"</strong> and
              collectively <strong>"App"</strong>). (both the ElevatorSense Box
              and the App hereinafter <strong>"ElevatorSense Solution"</strong>
              ).'
            </>,
            <>
              The provision of the ElevatorSense Box will be governed by a
              separate contract between User and WITTUR (
              <strong>"Box Contract"</strong>)
            </>,
            <>
              The App may also be offered by Affiliates of WITTUR, with WITTUR
              remaining the sole contracting party of User (as defined
              hereinafter) in such scenario with respect to the provision of the
              App. <strong>"Affiliate"</strong> means all companies that are
              majority owned or involve majority shareholding or equity
              interests in relation to each other, dependent and controlling
              companies, companies under the uniform management of a single
              controlling company, and companies that hold shares or equity
              interests in each other.
            </>,
            'WITTUR provides the App exclusively to entrepreneurs. An entrepreneur is a natural or legal person or a partnership with legal capacity who or which, when entering a legal transaction, acts in the exercise of its trade, business or profession. Consumers may not use the App.',
            <>
              The following terms entirely govern the legal relationship between
              WITTUR and the User with respect to the App (
              <strong>"Terms of Use"</strong>) and cover <i>inter alia</i>{' '}
              access and usage of the App, subject to additional or divergent
              terms as specified in <a href="#appendix_1">Appendix 1</a>. The
              Terms of Use become binding upon completion of the registration by
              User and a service agreement is concluded with WITTUR at such
              point in time (<strong>"Agreement"</strong>). The Terms of Use are
              stored by WITTUR and are accessible to the User at any time within
              the App.
            </>,
            'As an entrepreneur, the User has no right of withdrawal.',
          ],
        },
        {
          title: 'II. Scope',
          content: [
            <>
              The App is a mobile and web application that allows for services
              as described in the Box Contract (<strong>"Services"</strong>).
              The App also provides information, visualizations, notifications,
              instructions and evaluations on which appropriate measures should
              be taken (<strong>"Recommendations"</strong>). A hyperlink to
              WITTUR’s web shop for components of the ElevatorSense Solution is
              provided to the User alongside the Recommendations in the App.
            </>,
            <>
              The use of the App is subject exclusively to these Terms of Use as
              well as the information provided within the App. Deviating or
              conflicting terms and conditions do not form part of an agreement
              with WITTUR, unless WITTUR has expressly agreed to them in
              writing. Notwithstanding the preceding sentence, the User is aware
              that in addition to these Terms of Use, it may be required to
              comply with further terms of use of the respective app store
              operator (e.g. Google in case of the Google Play Store or Apple in
              case of the Apple App Store) if the User makes use of the Mobile
              App downloaded from the respective app store and that WITTUR has
              no influence on such further terms of use and consequently assumes
              no responsibility whatsoever therefore.
            </>,
            'Downloading the Mobile App requires suitable terminal devices and Internet access, through which costs for the connection to the Google Play Store / Apple App Store may be incurred.',
            <>
              The Parties acknowledge and agree that the Services to be provided
              in the local jurisdictions specified in{' '}
              <a href="#appendix_1">Appendix 1</a> require additional or
              divergent terms or requirements to be added to or in place of
              those set out in these Terms of Use. Thus, the contractual
              relationship between the Parties is subject to additional or
              divergent terms as specified in{' '}
              <a href="#appendix_1">Appendix 1</a>, if applicable (hereinafter:{' '}
              <strong>“Local Jurisdiction Terms”</strong>). In case of conflict
              between these Terms of Use and the Local Jurisdiction Terms, the
              Local Jurisdiction Terms shall prevail.
            </>,
          ],
        },
        {
          title: 'III. Remuneration',
          content: [
            'The use of the basis functionality of the App is free of charge. However, User is aware that the App is only usable together with the ElevatorSense Box for which different models for purchase or rent exist, that are subject to the Box Contract.',
            <>
              Additional charges may incur for special functions available
              within the App which Users may purchase (
              <strong>"Features"</strong>). The Features available to User may
              differ between the country in which the App is used.
            </>,
          ],
        },
        {
          title: 'IV. Provision of the App; Maintenance',
          content: [
            <>
              WITTUR shall provide reasonable access to and use of the App. For
              this purpose, WITTUR shall make the App accessible to registered,
              permanent and/or freelance employees of the User (
              <strong>"Authorized Personnel"</strong>).
            </>,
            "It is at WITTUR’s sole and absolute discretion to restrict access to the App in whole or in part, temporarily or permanently due to maintenance work, capacity concerns and other events beyond its control. WITTUR will typically maintain the App outside of the normal business hours, unless an immediate event requires, in WITTUR's sole and absolute discretion, maintenance during normal business hours. Any part of the App may change without notice at any time in WITTUR's sole and absolute discretion.",
            'WITTUR provides access to the Web App in a hosted environment, which allows the User to use the App over the internet without having him to install and operate the software on its own IT-Infrastructure.',
            "WITTUR shall use reasonable endeavors to maintain the availability of the Web App for the use by the User at the gateway between the public internet and the network of WITTUR's hosting servers. The Web App is available if it is usable at the gateway between the public internet and the network of WITTUR's hosting servers.",
            <>
              WITTUR provides updates of the App in accordance with the
              recognized state of the art without additional remuneration (
              <strong>"Updates"</strong>). Updates within the meaning of these
              Terms of Use are characterized by error corrections or minor other
              improvements without significant new functionalities. Updates do
              not include the delivery or provision of additionally offered new
              functionalities or additional components.
            </>,
            <>
              The User must install Updates for the Mobile App (i.e. client
              side) once available. Updates allow the Mobile App to receive up
              to date information and ensure its proper operation. Otherwise,
              WITTUR cannot assure that the Mobile App will function properly,
              or that the information provided is up to date. Should the User
              not install Updates but continue to use the outdated version of
              the Mobile App nonetheless, the User thereby waives any claims
              regarding defects and claims for payment of damages to which the
              User may be entitled.
            </>,
          ],
        },
        {
          title: 'V. User Account',
          content: [
            <>
              Access to the App may only be granted to the User and its
              Authorized Personnel after registering and creating a user account
              within the App (<strong>“User Account”</strong>).
            </>,
            'The User must name its Authorized Personnel to WITTUR.',
            'The User is solely and exclusively responsible for managing its Authorized Personnel, e.g. assigning roles and rights within the App. In particular, User shall cease access to the App once therespective persons are no longer working for the User.',
            <>
              The User is obliged to keep its access data to its User Account
              secret and to protect it from access by third parties. Should the
              User become aware of the loss or misuse of its access data or the
              suspicion of misuse of its user account, the User must notify
              WITTUR immediately and change the password using the functionality
              provided on the App. A disclosure of the login data to third
              parties is prohibited. The User may not use the login data of any
              other person to access the App. The User is solely and exclusively
              responsible for the activities of anyone accessing the App using
              the User's login data, including any Authorized Personnel, even if
              the activities were not, in fact, authorized by User.
            </>,
          ],
        },
        {
          title: 'VI. User Obligations',
          content: [
            <>
              The User is solely and exclusively responsible for any content
              User uploads, publishes or otherwise makes publicly available
              through the App. By using an account on the App, User represents
              and warrants to WITTUR that the information it provides to WITTUR
              (<strong>"User Content"</strong>) is true, accurate and complete.
              Further, User guarantees to uphold User Content as true, accurate
              and complete. WITTUR does not monitor the User Content for
              completeness, accuracy, legality, availability, quality or
              suitability for any purpose.
            </>,
            <>
              The Services may only be conducted while the ElevatorSense Box is
              connected to the internet. The User recognizes that it is in its
              sole and exclusive responsibility to facilitate the ElevatorSense
              Box technically. The User hereby waives any rights and thereof
              resulting claims to which the User may be entitled due to
              dysconnectivity of the ElevatorSense Box.
            </>,
            'In order to perform the Services, the User is obliged to strictly follow installation and operation instructions provided in the App.',
            <>
              If WITTUR provides downloadable updates and/or upgrades for the
              ElevatorSense Box, the User is obligated to install these in order
              to ensure proper interaction between the App and the ElevatorSense
              Box. Otherwise, WITTUR cannot assure that the Services may be
              conducted properly. Should the User not install an update and/or
              upgrades that are provided but continue to use the outdated
              version of the ElevatorSense Box nonetheless, the User thereby
              waives any claims regarding defects and claims for payment of
              damages to which the User may be entitled.
            </>,
            'The User is prohibited from any activities on or in connection with the App that violate applicable law or infringe the rights of third parties.',
            {
              text: 'Furthermore, the User is also prohibited from performing the following activities irrespective of any violation of the law:',
              list: [
                'the distribution of viruses, trojans and other harmful files;',
                'sending junk or spam mails and chain letters;',
                'the dissemination of offensive, sexually oriented, obscene or defamatory content or communication as well as such content or communication which is/are suitable to promote or support racism, fanaticism, hatred, physical violence or illegal acts (either explicitly or implicitly in each case);',
                'the request of other users to disclose passwords or personal data for commercial or illegal purposes;',
                'the dissemination and/or public reproduction of content available on the App.',
              ],
            },
            'WITTUR reserves the right to suspend the User from using the App at any time, if these Terms of Use, applicable law or the rights of third parties are violated or WITTUR has reasonable grounds for believing they may be violated.',
          ],
        },
        {
          title: 'VII. Intellectual Property Rights',
          content: [
            <>
              WITTUR grants to the User a revocable, non-exclusive,
              non-transferable, non-sublicensable, limited in time for the term
              of the Agreement, right to use the App in accordance with these
              Terms of Use (<strong>"License"</strong>). The owner of the
              intellectual property rights in the App is WITTUR, or its business
              partners or other third parties who have made the respective
              content and intellectual property rights under license available
              to WITTUR. The App and the content made available on the App may
              therefore not be changed, extended, edited, copied and/or
              otherwise distributed by the User.
            </>,
            <>
              Recommendations generated by the App may be used by User for
              supporting own services towards User's customers. WITTUR will not
              assume any responsibility or liability with respect to these
              services and any actions by User or its customer that are derived
              therefrom.
            </>,
            <>
              Unless otherwise provided for in these Terms of Use, the User
              agrees not to reproduce, distribute, modify or create derivative
              works of the App or any other components thereof and not to
              reverse engineer or decompile the App except as the same may be
              authorized under mandatory law.
            </>,
            <>
              The User acknowledges and agrees that WITTUR is allowed to use the
              data that is processed through the App in anonymized form (within
              the meaning of applicable data protection laws) for any business
              purposes of WITTUR and, in particular, to reproduce and to grant
              third party access to such data. WITTUR shall not de-anonymize the
              data and is obliged to take all appropriate measures to prevent
              any de-anonymization of the data. If third party access to the
              data is granted, WITTUR shall oblige the third party to not
              de-anonymize the data and to take all appropriate measures to
              prevent any de-anonymization of the data. To the extent required,
              User shall grant WITTUR an irrevocable, worldwide, non-exclusive,
              royalty-free, sublicensable license to use such data. The business
              purposes shall include but not be limited to the development,
              manufacturing, enhancement, and/or marketing of products and
              services.
            </>,
          ],
        },
        {
          title: 'VIII. Term and Termination',
          content: [
            <>
              The Agreement has an unlimited term and User shall be entitled to
              terminate the Agreement at any time for convenience. User
              acknowledged and is aware (i) that the functionality of the App
              will be limited or suspended upon such point in time where the
              subscription term and/or usage rights for the ElevatorSense Box
              under the Box Contract end and (ii) that User's termination for
              convenience of the Agreement will not impact its obligations that
              may still arise from the Box Contract.
            </>,
            <>
              Unless otherwise agreed with the User, licenses for Features
              purchased by the User are valid until the end of the respective
              term for such Feature. If the User does not terminate the license
              for a Feature three (3) months before the end of the term, the
              term shall be automatically extended for twelve (12) months and
              User shall be obliged to pay additional charges as agreed for the
              term. The right to terminate a license for a Feature with
              immediate effect for good cause remains unaffected. Section VIII.1
              shall apply mutatis mutandis to Features.
            </>,
            <>
              Upon termination of the Agreement, the User shall (i) immediately
              cease use of the App and acknowledges that WITTUR may block User's
              access to it and its Authorized Personnel and (ii) immediately,
              but no later than in 5 (five) days, delete and destroy any
              downloaded content and printed materials. User has no right, title
              or interest (and no copyright, trademark or other intellectual
              property right) in or to the App or any content, information,
              materials, applications or other functionalities related thereto.
            </>,
          ],
        },
        {
          title: 'IX. Rights in Case of Defects',
          content: [
            <>
              The App shall be provided and maintained in a condition suitable
              for contractual use. The obligation to maintain does not include
              the adaptation of the functionalities of the App itself to changed
              operating conditions and technical and functional developments
              that are not related to the ElevatorSense Box, the adaptation to
              the scope of functions of competing products or the creation of
              compatibility with new browser versions.
            </>,
            {
              text: (
                <>
                  WITTUR warrants that the App is free of third-party rights, in
                  particular intellectual property rights, that restrict or
                  preclude its use in accordance with these Terms of Use. In
                  case of any allegation that the use of the App, as authorized
                  under these Terms of Use, violates or infringes the patent,
                  copyright, trademark or other intellectual property rights of
                  any third party (<strong>"Violation"</strong>)
                </>
              ),
              list: [
                <>
                  WITTUR has the right to either modify the App so that the
                  Violation no longer applies or to obtain authorization for the
                  App to be used in accordance with the Terms of Use, without
                  limitation and without additional costs to the User;
                </>,
                <>
                  the User agrees to provide WITTUR with prompt notice in text
                  form (i.e., including e-mail) and all information in
                  connection with the Violation; and
                </>,
                <>
                  the User shall cooperate with and provide assistance to WITTUR
                  in ceasing the Violation, as can reasonably be expected from
                  it.
                </>,
              ],
            },
            ' The right to claim damages is subject to the limitations of liability pursuant to the following Section X..',
          ],
        },
        {
          title: 'X. Limitation of Liability',
          content: [
            'WITTUR is liable without limitation for damages caused by willful intent or gross negligence of WITTUR or its vicarious agent, for damages caused by injury to life, body or health as well as within the scope of applicable product liability laws.',
            <>
              For damages and expenses caused by simple negligence, WITTUR shall
              only be liable for typically foreseeable damages if these damages
              and expenses were caused by WITTUR violating essential contractual
              obligations. Contractual obligations are essential if their
              fulfilment is required to achieve the purpose of the Agreement and
              the User may therefore rely on their fulfilment.
            </>,
            'In all other respects WITTUR shall not be liable.',
            <>
              For the avoidance of doubt, WITTUR shall in no event be liable for
              a damage or loss if and to the extent such damage or loss arises
              from the User's failure to comply to recommendations or notices
              provided by or contained in the App.
            </>,
          ],
        },
        {
          title: 'XI. Limitations of Liability for Provided Content',
          content: [
            <>
              There shall be no liability for any information provided in the
              Services and any Recommendations provided in the App (
              <strong>“Provided Content”</strong>) by WITTUR. As resulting from
              Section VII.2, WITTUR shall not be liable with respect to the use
              of the Provided Content for or by User's own customers.
            </>,
            <>
              When providing information in the Services, the information may
              not be accurate as the data generated by the ElevatorSense Box is
              subject to external conditions.
            </>,
            <>
              When providing Recommendations the App provides certain
              probabilities for a particular outcome and recommends actions
              accordingly. However, the App does not make any conclusive
              assessment. Making a due assessment, including buying
              ElevatorSense products in WITTUR’s web shop as replacements,
              remains User's sole and exclusive responsibility.
            </>,
            {
              text: 'The User shall bear in mind the following limitations which apply to the Provided Content generated by this App:',
              list: [
                <>
                  The Provided Content is generated using highly complex
                  algorithms. However, it is obviously not possible for an
                  algorithm to model every single feature of an individual
                  event. The App therefore provides a continually improved
                  approximation for customized decision-making support.
                </>,
                <>
                  New and updated data, which form the basis of the Provided
                  Content, are constantly fed into the algorithm. WITTUR
                  compiles this data to the best of its knowledge with
                  professional due diligence. However, no guarantee can be made
                  as to whether the data is correct, complete, and up to date.
                  Errors in a data record could lead to unreliable Provided
                  Content.
                </>,
              ],
            },
          ],
        },
        {
          title: 'XII. Data Protection',
          content: [
            <>
              Since data protection is a high priority at WITTUR, WITTUR
              complies stringently with applicable data protection laws, when
              processing (e.g. collecting, using, disclosing etc.) personal
              data. If and to the extent not informing otherwise about the
              specific processing of personal data by WITTUR, information about
              such processing is included within the applicable privacy policy
              which is accessible in the App.
            </>,
            'The User shall comply with the applicable data protection laws when providing personal data to WITTUR, e.g. through the App.',
          ],
        },
        {
          title: 'XIII. Changes / Transfer of Agreement',
          content: [
            <>
              WITTUR is entitled to amend these Terms of Use at any time. The
              User will be notified of such changes at least sixty (60) calendar
              days prior to the planned implementation of the changes. If the
              User does not object within thirty (30) calendar days from receipt
              of the notification and continues the use of the App after expiry
              of the objection period, the changes shall be deemed to be validly
              agreed from the expiry of the objection period. In such
              notification, the User will be informed of its right of objection
              and its consequences.
            </>,
            <>
              WITTUR reserves the right to transfer this Agreement with the User
              to another company. Users will be informed about such a transfer
              and may rescind from the Agreement within ten (10) calendar days
              after having received the information on the transfer.
            </>,
          ],
        },
        {
          title: 'XIV. Miscellaneous',
          content: [
            <>
              Should one or more provisions of these Terms of Use be invalid or
              unenforceable due to violation of applicable law or other reason,
              the rest of the Terms of Use shall remain valid. The invalid or
              unenforceable provision shall be replaced by a valid, enforceable
              provision, which most approximately represents the mutual
              understanding of the parties.
            </>,
            <>
              The law governing validity, interpretation and implementation of
              these Terms of Use is German law with the exclusion of its rules
              on the conflict of laws and the courts competent to have
              jurisdiction over any dispute arising out of, or relating to these
              Terms of Use, are the courts of Munich.
            </>,
          ],
        },
        {
          title: 'XV. International Users',
          content: [
            <>
              This App is operated, monitored and updated by WITTUR GmbH. It is
              only intended for use in the countries listed in{' '}
              <a href="#appendix_1">Appendix 1</a>. Should the User access this
              App and/or download content from outside of these countries, the
              User is solely and exclusively responsible for ensuring that such
              use is compliant with the relevant local legislation.
            </>,
          ],
        },
      ],
    },
    appendix: {
      title: 'Appendix 1: DISTRIBUTION COUNTRY AND LOCAL JURISDICTION TERMS',
      content: [
        {
          heading: 'Distribution country Additional or Divergent Terms',
          details: [
            {
              country: 'India',
              content: [
                'Section V. (User Account) is supplemented as follows:',
                <>
                  The User consents to WITTUR or its Affiliates collecting its
                  password for the purpose of enabling access to, and use of,
                  the App. The password may be processed by Wittur or its
                  Affiliates in accordance with the Privacy Policy.
                </>,
                <>
                  Section X.2. (Limitation of Liability) is amended as follows:
                  For damages and expenses caused by WITTUR’s material breach,
                  WITTUR shall only be liable for damages which naturally arise
                  in usual course from such breach, or which the User knew, when
                  they agreed to the Terms of Use, to be likely to result from
                  the breach of it and shall (i) not include any damages arising
                  from any remote and indirect loss or damage sustained by
                  reason of the material breach and (ii) be limited to the
                  consideration received from the User for the ElevatorSense
                  Solution, which the User and WITTUR agree is a fair,
                  reasonable and genuine pre-estimate for any damages arising by
                  reason of any material breach.
                </>,
              ],
            },
            {
              country: 'United Kingdom',
              content: [
                <>
                  Section X.2. (Limitation of Liability) is amended as follows:{' '}
                  <br /> WITTUR shall only be liable for typically foreseeable
                  damages and expenses if these damages and expenses were caused
                  by WITTUR’s material breach, which WITTUR has not remedied
                  within thirty (30) days from receipt of notice of the breach
                  from Customer. Subject to the foregoing, WITTUR excludes all
                  other liability (whether direct or indirect, and regardless of
                  the cause) under these Terms of Use.
                </>,
              ],
            },
          ],
        },
      ],
    },
  },
  testCompleted: 'Test completed.',
  testResults: 'Test Results',
  time: 'Time',
  tooHigh: 'Too high',
  tooLoose: 'Too loose',
  tooLow: 'Too low',
  tooTight: 'Too tight',
  top: 'top',
  topToBottom: 'Top to Bottom',
  touching: 'touching',
  transformer: 'Transformer',
  truckRoller: 'Truck roller',
  truckRollerInferior: 'Truck roller inferior',
  truckRollerSuperior: 'Truck roller superior',
  twisted_cd_panels: 'Twisted CD Panels',
  twisted_ld_panels: 'Twisted Panels',
  unlinkBox: 'Unlink Box',
  unreadNotifications: (n: number) =>
    `${n} unread notification${n === 1 ? '' : 's'}`,
  unshare: 'Unshare',
  update: 'Update',
  updateRequired: 'Update Required',
  updateStaticData: 'Update Data',
  updating: 'Updating...',
  upToDate: 'Up-to-date',
  usage: 'Usage',
  usedElectronics: 'Elevators used',
  validFrom: 'Valid from',
  validUntil: 'Valid until',
  vibration: 'Vibration',
  viewErrorLog: 'View Error Log',
  waitWhileInitializingTest: 'Please wait while initializing the test ...',
  waitingForData: 'Waiting for new data to arrive',
  waitingForTestResults: 'Waiting for test results ...',
  warning: 'Warning',
  warnings: 'Warnings',
  warrantyExtensionRequestedOn: (date: string) =>
    `Warranty extension was request on ${date}`,
  whyNotReplaced: 'Why was the component not replaced?',
  wifiScanned: 'WIFI has been scanned',
  years: 'years',
  yes: 'Yes',
  zero_position: 'Zero Position',
  zero_position_description: 'The zero position is not correct.',
  zero_position_eco_actions:
    'ECO+ procedure:\n  1) Set S1DIP1 to ON (Service mode).\n  2) Close the door by hand (zero position).\n  3) Press the learn button > 10s (Longlearn).\n  4) Open and close the door using the service drive button.\n  5) Hold down the buttons at the door open end and at the door close end for > 2s.\n  Result: The state LED must go out after 2 cycles.\n  ',
  zero_position_midi_actions:
    'MIDI/SUPRAV2 procedure:\n  Close the door by hand (zero position).\n  Follow the instructions in the pictogram.',
  yourBuilding: 'Your Building',
  yourBuildings: 'Your Buildings',
};

export type Translations = typeof en;
export type TranslationKey = keyof Translations;

export type TranslatableErrorKey =
  | 'programmingErrorNoPopup'
  | 'programmingErrorConnection'
  | 'programmingErrorRequest';

type StringKey<T extends TranslationKey> = Translations[T] extends string
  ? T
  : never;

export type StringTranslations = {
  [K in keyof Translations as StringKey<K>]: Translations[K];
};
export type StringTranslationKey = keyof StringTranslations;
