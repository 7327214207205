import React from 'react';

export const pl = {
  aborted: 'Przerwano',
  accelerationDescription:
    'Współczynnik przyspieszenia używany do obliczania krzywej.',
  accelerationNote:
    'Ten współczynnik musi być wystarczająco wysoki, aby osiągnąć ustawione prędkości, w przeciwnym razie drzwi nie osiągną żądanej prędkości.',
  accelerationTitle: 'Przyspieszenie',
  acceptTerms: 'Akceptuję warunki użytkowania',
  add: 'Dodaj',
  addBuilding: 'Dodaj Budynek',
  addLift: 'Dodaj Windę',
  addressHint:
    'Wprowadź nazwę firmy lub budynku, aby uzyskać sugestie dotyczące adresu i obrazu.',
  adjustCameraSettings:
    'Możesz także dostosować ustawienia kamery za pomocą menu rozwijanego.',
  admin: 'Administrator',
  alreadyHasSubcontractorsError:
    'Jako podwykonawca nie możesz mieć podwykonawców. Usuń istniejących podwykonawców przed zaakceptowaniem tego zaproszenia.',
  all: 'Wszystko',
  allBuildings: 'Wszystkie Budynki',
  allBuildingsSubscription: 'Ze wszystkich budynków',
  alphabetical: 'Alfabetycznie',
  ambientTemperature: 'Temperatura w Skrzynce',
  assetNumber: 'ID Windy',
  assetNumberHint:
    'Wprowadź ID (np. numer instalacji/urządzenia) windy, jeśli masz go pod ręką.',
  auto: 'Automatycznie',
  back: 'Wstecz',
  backToDashboard: 'Wróć do Panelu',
  belt_tension: 'Napięcie Paska',
  belt_tension_actions:
    'Dostosuj napięcie paska zgodnie z instrukcją instalacji.',
  beltclamp: 'Mocowanie Paska',
  belt_tension_description: 'Napięcie paska jest zbyt wysokie/niskie.',
  betweenDoorCycle: 'Między cyklem drzwi',
  bottom: 'Dół',
  bottomToTop: 'Od Dołu do Góry',
  boxLinkedToOtherLift: (lift: string, building: string) =>
    `Skrzynka jest powiązana z ${lift} w ${building}. Kontynuować?`,
  boxNotReady: 'Wybrana skrzynka nie jest gotowa.',
  boxOfflineHint:
    'Jeśli chcesz zaktualizować jedną ze skrzynek wymienionych poniżej, najpierw podłącz ją do internetu.',
  boxScannedNoIdFound: 'Skrzynka zeskanowana, ale nie znaleziono ID.',
  boxSoftware: 'Oprogramowanie',
  boxSoftwareStatusError:
    'Nie można pobrać statusu skrzynek: sprawdź połączenie internetowe skrzynki i spróbuj ponownie. Jeśli problem będzie się powtarzał, skontaktuj się z przedstawicielem Wittur.',
  boxSoftwareTitle: 'Oprogramowanie Skrzynki',
  boxSoftwareUpdateError:
    'Nie udało się zaktualizować oprogramowania skrzynki: sprawdź połączenie internetowe skrzynki i spróbuj ponownie. Jeśli problem będzie się powtarzał, skontaktuj się z przedstawicielem Wittur.',
  box_id: 'ID Skrzynki',
  brakeParDescription:
    'Ze względu na ustawienie tego parametru wpływa on na opóźnienie i pełzanie do końca otwarcia/zamknięcia (wstępnie ustawione na „5”).',
  brakeParNote:
    'Możliwe ustawienia: „0”..„9”, ale najwcześniejsze hamowanie osiąga się przy parametrze „0”, a najpóźniejsze opóźnienie przy parametrze „9”.',
  brakeParTitle: 'Parametr Hamowania',
  buffer: 'Bufor',
  building: 'Budynek',
  buildingHasNoElevator: 'Ten budynek nie ma wind.',
  buildingName: 'Nazwa',
  buildings: 'Budynki',
  buildingsWithoutValidAddress: 'Budynki bez poprawnego adresu',
  bushingClose: 'Zamknięcie Tulei',
  bushingOpen: 'Otwarcie Tulei',
  cablechain: 'Łańcuch Kablowy',
  cableTensorSpring: 'Sprężyna Napinacza Kabla',
  callFloorsInstruction: (
    <>
      Otwórz drzwi i <b>zadzwoń na wszystkie inne piętra</b>. Wyjdź z kabiny i
      kliknij "Kontynuuj".
    </>
  ),
  cameraMode: 'Tryb kamery',
  cancel: 'Anuluj',
  car: 'Kabina',
  carDoor: 'Drzwi Kabiny',
  carDoorCounter: 'Licznik Cykli Drzwi Poprzedniej Elektroniki',
  carDoorModified: 'Drzwi kabiny zostały zmodyfikowane',
  cd_counter_rollers_installation: 'Rolki Licznika',
  cd_counter_rollers_installation_actions:
    'Dla każdych drzwi przystankowych upewnij się, że rolki można łatwo obracać ręką i że ich odległość od szyny nie jest zbyt duża. Jeśli to nastąpi, poluzuj rolki i dostosuj je tak, aby były jak najbliżej szyny, ale jej nie dotykały i można je było łatwo obracać ręką. Jeśli musisz zdemontować sprzęgło, postępuj zgodnie z instrukcjami instalacji.',
  cd_counter_rollers_installation_description:
    'Rolki licznika CD nie są poprawnie zamontowane.',
  cd_roller: 'Rolki CD',
  chooseFloorOrCardoor: 'Wybierz Piętro / Drzwi Kabiny',
  choosePartNumber: 'Wybierz Numer Części',
  chooseReason: 'Wybierz powód (opcjonalne)',
  chooseStartingFloorInstructions:
    'Wybierz piętro początkowe dla biegu testowego. Piętro początkowe może być najwyższym lub najniższym piętrem.',
  clearDriveErrors: 'Wyczyść',
  clearOpening: 'Wolny otwór',
  close: 'Zamknij',
  closeDoorInstructions: (floor: string) => (
    <>
      Udaj się na <b>{floor}</b> piętro. Upewnij się, że drzwi kabiny są
      zamknięte przed kontynuowaniem.
    </>
  ),
  closeForcePotiDescription:
    'Ustaw limit siły używanej przy otwieraniu i zamykaniu drzwi.',
  closeForcePotiNote:
    'Regulowane za pomocą potencjometru na elektronice. Na nowszych elektronach takich jak MIDIV2 można ustawić tutaj w trybie serwisowym, jeśli jest to konieczne.',
  closeForcePotiTitle: 'Limit Siły Zamknięcia',
  closeHoldForceDescription: 'Siła stosowana w pozycji zamknięcia.',
  closeHoldForceNote:
    'Siła ta powinna wynosić od 50N do 70N. Zbyt niska siła może powodować niechciane otwieranie sprzęgła. Zbyt wysoka siła nagrzewa silnik niepotrzebnie i może prowadzić do pracy w trybie redukcji.',
  closeHoldForceTitle: 'Siła Utrzymania Zamknięcia',
  closeSpeedDescription:
    'Maksymalna prędkość drzwi w kierunku zamknięcia. Krzywa zamykania jest obliczana w celu osiągnięcia tej ustawionej prędkości, jeśli to możliwe.',
  closeSpeedNote:
    'Rzeczywista prędkość drzwi jest również związana z przełącznikami DIP prędkości napędu. Ustawienie na prędkość 4 oznacza 100% wartości 2.',
  closeSpeedTitle: 'Prędkość Zamknięcia',
  closing_device_failure: 'Urządzenie Zamykania',
  closing_device_failure_actions:
    'Sprawdź, czy urządzenie zamykające dla drzwi przystankowych działa poprawnie we wszystkich jego elementach (spirator, sprężyna zamykająca i ciężar zamykający). Drzwi przystankowe muszą się samodzielnie zamykać z każdej pozycji drzwi.',
  closing_device_failure_description:
    'Urządzenie zamykające nie jest prawidłowo zainstalowane. Siła potrzebna do zamknięcia drzwi jest zbyt duża.',
  companyName: 'Nazwa Firmy',
  completed: 'Zakończono',
  componentDetails: 'Szczegóły Komponentów',
  componentDetailsHint:
    'Dla niektórych komponentów istnieje wiele opcji numerów części, które należy wybrać. Jest to ważna informacja dla funkcji konserwacji zapobiegawczej. Kliknij wymienione elementy, aby wybrać rzeczywiście zainstalowaną część.',
  confirm: 'Potwierdź',
  confirmDeleteBuilding: 'Czy na pewno chcesz usunąć ten budynek?',
  confirmDeleteErrorHistory: 'Czy na pewno chcesz usunąć tę historię błędów?',
  confirmDeleteFavoriteBuilding:
    'Czy na pewno chcesz usunąć ten budynek z ulubionych?',
  confirmDeleteLift: 'Czy na pewno chcesz usunąć tę windę?',
  confirmInvite: 'Potwierdź i Połącz',
  connectionErrorMessage:
    'Nie można pobrać danych z powodu problemu z połączeniem. Spróbuj ponownie później.',
  continue: 'Kontynuuj',
  contractorBuilding: 'Budynek Zleceniodawcy',
  contractorBuildings: 'Budynki Zleceniodawców',
  contractors: 'Zleceniodawcy',
  couplerelease: 'Zwolenie Sprzęgła',
  couplerFriction: 'Tarcie Sprzęgła',
  couplerMovementParameter: 'Parametr Ruchu Sprzęgła',
  couplerSpeedDescription:
    'Ta wartość definiuje maksymalną prędkość paska w zdefiniowanym obszarze sprzęgła.',
  couplerSpeedTitle: 'Prędkość Sprzęgła',
  couplerWitdhDescription:
    'Ta wartość definiuje odległość paska w obszarze sprzęgła. Ta wartość jest wynikiem procedury uczenia.',
  couplerWitdhTitle: 'Szerokość Sprzęgła',
  currentVersion: 'Obecna Wersja',
  couplerwidth: 'Szerokość Sprzęgła',
  date: 'Data',
  delete: 'Usuń',
  deleteErrorHistory: 'Usuń historię błędów',
  directConnection: (
    <>
      Połącz się z Wi-Fi skrzynki ELEVATORSENSE, skanując kod QR wydrukowany na
      skrzynce za pomocą aplikacji aparatu w telefonie.
    </>
  ),
  disconnectProgrammingTool: 'Odłącz Narzędzie Programowania Bezprzewodowego',
  divpulley: 'Koło Pasowe',
  documentation: 'Dokumentacja',
  doorContact: 'Styk Drzwiowy',
  doorCycleCounter: 'Licznik Cyklów Drzwi',
  doorCycleError1: 'Nie odebrano danych cykli drzwi.',
  doorCycleError2: 'Ruch cykli drzwi został zakłócony.',
  doorCycleError4: 'Wykryto ponowne otwarcie drzwi.',
  doorCycleError5: 'Wykryto ponowne zamknięcie drzwi.',
  doorCycleError7: 'Nie odebrano żadnych prawidłowych cykli drzwi.',
  doorCycles: 'Cykl Drzwi',
  doorFriction: 'Tarcie Drzwi',
  doorInstallationDate: 'Data Instalacji Drzwi',
  doorIsClosed: 'Tak, drzwi są zamknięte',
  doorModel: 'Model Drzwi',
  doorModelNotSupported:
    'Wybrany model drzwi nie jest dostępny dla konserwacji zapobiegawczej.',
  doorMovementParameter: 'Parametr Ruchu Drzwi',
  doorPosition: 'Pozycja Drzwi',
  doorSerialNumber: 'Numer Seryjny Drzwi',
  doorType: 'Typ Drzwi',
  doorwidth: 'Szerokość Drzwi',
  downloadCertificate: 'Pobierz Certyfikat',
  downloadLicense: 'Pobierz wszystkie licencje jako CSV',
  downloadRequestedExtendedWarranty:
    'Pobierz wszystkie wnioski o przedłużenie gwarancji',
  drive_motor_noise: 'Hałas Silnika Napędu',
  driveesn: 'ESN',
  drivesoftware: 'Firmware',
  duringLiftMovement: 'Podczas ruchu windy',
  editBuilding: 'Edytuj Budynek',
  editLift: 'Edytuj Windę',
  elecBoard: 'Płyta Elektryczna',
  elevatorIdNotFound: (
    <>
      Aby zażądać przedłużenia gwarancji, musisz wprowadzić numer seryjny drzwi
      w ustawieniach windy. <b>Kliknij tutaj, aby przejść do ustawień.</b>
    </>
  ),
  elevatorInstallationDate: 'Data Instalacji Windy',
  elevatorUsageType: 'Typ Użytkowania Windy',
  email: 'E-mail',
  encoderTemperature: 'Temperatura Enkodera',
  error: 'Błąd',
  errorDescription: 'Opis Błędu',
  errorEarlierThanInstallationDate:
    'Nie można wprowadzić daty wcześniejszej niż data instalacji.',
  errorInvalid: 'Wprowadź prawidłową wartość.',
  errorInvalidOperation: 'Nieprawidłowa operacja',
  errorMessages: 'Wiadomości Błędów',
  errorMessagesDescription: 'Wyświetlanie zapisanych wiadomości błędów.',
  errorNotFound: 'Nie znaleziono zasobu',
  errorRetryCamera:
    'Nie udało się uzyskać dostępu do kamery. Ponawianie, strona zostanie odświeżona...',
  errorRequired: 'To pole jest wymagane.',
  errorUnableToAccessCamera:
    'Nie udało się uzyskać dostępu do kamery po wielokrotnych próbach. Odśwież stronę lub sprawdź uprawnienia kamery.',
  errorUnknownOrForbiddenBox:
    'Skrzynka jest niedostępna. Jeśli potrzebujesz pomocy, skontaktuj się z obsługą Wittur.',
  errors: 'Błędy',
  errorsEmpty: 'Brak wiadomości.',
  errorsEmptyHint: 'Sprawdź swoje filtry i być może włącz dodatkowe typy.',
  extendWarranty: 'Przedłuż Gwarancję',
  fatalError: 'Krytyczny Błąd',
  fatalErrors: 'Krytyczne Błędy',
  favorite: 'Ulubione',
  favorites: 'Ulubione',
  figureAdjustment: 'Regulacja',
  figureAugustaEVO: 'Przykład Augusta EVO',
  figureClosingWeight: 'Ciężar Zamykania',
  figureEco: 'ECO+',
  figureHydra: 'Przykład Hydra',
  figureMidi: 'MIDI/SUPRAV2',
  figureNotOk: 'Nie OK',
  figureOk: 'OK',
  figureSGV: 'Przykład SGV',
  figureSpirator: 'Spirator',
  figureSpring: 'Sprężyna Zamykania',
  firstName: 'Imię',
  floor: 'Piętro',
  floors: 'Piętra',
  followingBoxScanned: 'Zeskanowano następującą skrzynkę',
  forgotPassword: 'Zapomniałeś hasła?',
  forgotPasswordText: 'Wprowadź swój adres e-mail, aby zresetować hasło.',
  groundFloor: 'Parter',
  home: 'Strona Główna',
  incompleteElevatorSettings:
    'Aby użyć konserwacji zapobiegawczej, potrzebujemy więcej informacji o tej windzie. Przejdź do ustawień windy i uzupełnij brakujące informacje.',
  initialized: 'Zainicjalizowano',
  installationControl: 'Kontrola Instalacji',
  installationControlDescription:
    'Wykonuj automatyczne kontrole w celu wykrycia powszechnych problemów z instalacją.',
  installationControlExecutedBy: 'Wykonane przez',
  installationControlHeadline: 'Ostatni Status Instalacji',
  installationControlHistory: 'Historia Biegu',
  installationControlHistoryEmpty: 'Brak zapisanych biegów instalacyjnych',
  installationControlInstructions: 'Pokaż Instrukcje',
  installationControlSubheadline: 'Podsumowanie wszystkich biegów',
  installationDateErrorMessage:
    'Nie można wprowadzić wcześniejszej daty instalacji drzwi niż daty instalacji windy',
  instantSubscription: 'Natychmiastowe powiadomienie',
  instantSubscriptionExplanation: (
    <>
      <b>Natychmiastowe powiadomienie</b> to powiadomienie wysyłane natychmiast
      po wystąpieniu krytycznego błędu w windzie. Krytyczne błędy to błędy,
      które mogą prowadzić do natychmiastowego uszkodzenia windy.
    </>
  ),
  invitationExpired: 'Link zaproszenia jest już nieaktywny.',
  inviteAsManager: 'Użytkownik może zapraszać innych użytkowników.',
  inviteCompanyWithSubcontractorsError:
    'Firma, którą próbujesz zaprosić, już posiada podwykonawców i nie może zostać dodana jako podwykonawca. Muszą usunąć istniejących podwykonawców lub wybrać inną firmę.',
  inviteCoworkers: 'Zaproś współpracowników',
  inviteSubcontractor: 'Zaproś Podwykonawcę',
  inviteToBeSubcontractorMessage: (companyName: string) =>
    `${companyName} zaprosiła Cię do bycia ich podwykonawcą w ElevatorSense.`,
  inviteToSignUpMessage: (companyName: string) =>
    `Firma ${companyName} zaprosiła Cię do rejestracji w ElevatorSense jako ich podwykonawca. Proszę wypełnij formularz, aby się zarejestrować.`,
  ipaddress: 'Adres IP',
  joinText: (name: string) => (
    <>
      Zarejestruj się, aby dołączyć do organizacji <i>{name}</i>.
    </>
  ),
  landingDoor: 'Drzwi Przystankowe - Piętro',
  landing_lock_roller_adjustment: 'Regulacja Rolek Zamek Przystankowy',
  landingCarDoorSpareParts: 'Części Zapasowe Drzwi Przystankowych/Kabiny',
  lastError: 'Ostatni Błąd',
  lastExchangeDate: 'Data Ostatniej Wymiany',
  lastMaintenanceDate: 'Data Ostatniej Konserwacji',
  lastMaintenanceScope: 'Zakres Ostatniej Konserwacji',
  lastMaintenanceScopeHint:
    'Wybierz zakres konserwacji zalecany przez Wittur, który został faktycznie przeprowadzony w ostatniej dacie konserwacji.',
  lastName: 'Nazwisko',
  lastTimeDoorElectronicsChange: 'Data Wymiany Elektroniki Drzwiowej',
  latestSoftwarePackVersion: (version: string) =>
    `Wersja pakietu oprogramowania: ${version}`,
  ld_counter_rollers_installation: 'Rolki Licznika',
  ld_counter_rollers_installation_actions:
    'Dla każdych drzwi przystankowych upewnij się, że rolki można łatwo obracać ręką i że ich odległość od szyny nie jest zbyt duża. Jeśli to nastąpi, poluzuj rolki i dostosuj je tak, aby były jak najbliżej szyny, ale jej nie dotykały i można je było łatwo obracać ręką. Jeśli musisz zdemontować sprzęgło, postępuj zgodnie z instrukcjami instalacji.',
  ld_counter_rollers_installation_description:
    'Rolki licznika LD nie są poprawnie zamontowane.',
  ld_roller: 'Rolki LD',
  left: 'Lewo',
  licenseRequired:
    'Nie posiadasz ważnej licencji na ten produkt. Skontaktuj się ze swoim przedstawicielem handlowym Wittur, aby zakupić produkt ElevatorSense.',
  lift: 'Winda',
  liftMovementDistance: 'Ruch Windy',
  liftSetup: 'Konfiguracja Windy',
  liftSetupComplete: 'Konfiguracja zakończona.',
  liftSetupError: 'Odpowiednie dane są niekompletne lub brakujące.',
  liftSetupError40: 'Ruch windy nie odpowiada biegowi konfiguracji.',
  liftSetupIncomplete: 'Konfiguracja niekompletna.',
  liftSetupPending:
    'Proszę przeprowadzić bieg konfiguracji w celu początkowej konfiguracji ElevatorSense na swojej windzie. Bez biegu konfiguracji nie będziesz mógł korzystać z niektórych funkcjonalności produktów ElevatorSense lub wyniki produktów ElevatorSense mogą się różnić. Kliknij tutaj, aby przeprowadzić bieg konfiguracji.',
  liftSetupRepeat:
    'Bieg konfiguracji nie powiódł się. Proszę powtórzyć bieg, uwzględniając opis procesu.',
  lifts: 'Windy',
  linkBox: 'Połącz Skrzynkę',
  linkToWebshop: (link: string) => (
    <>
      Jeśli masz skrzynkę ElevatorSense i chcesz aktywować licencję, kliknij{' '}
      <a href={link} className="clickable-link">
        tutaj
      </a>
      .
    </>
  ),
  loading: 'Ładowanie',
  login: 'Zaloguj się',
  loginText: 'Zaloguj się na swoje konto.',
  logout: 'Wyloguj się',
  manageAccess: 'Zarządzaj Dostępem',
  manageElevators: 'Zarządzaj Windami',
  manageSharedBuildingsAndElevator:
    'Zarządzaj Budynkami/Windami Udostępnionymi',
  mandatory: 'Wykonaj Sprawdzenie Wymiany',
  manual: 'Ręcznie',
  maxElectronics: 'Maksymalna liczba wind',
  maxUsage: 'Maksymalne Użycie',
  measured: 'Zmierzony',
  members: 'Członkowie',
  misalignment_cd_vs_ld: 'Wyrównanie Drzwi/Sprzęgła',
  misalignment_cd_vs_ld_actions:
    'Dostosuj rolki zamka drzwi przystankowych. Prześwit pomiędzy łopatkami sprzęgła a rolkami musi być taki sam po lewej i prawej stronie.',
  misalignment_cd_vs_ld_description:
    'Sprzęgło drzwi kabinowych jest niewyrównane z rolkami zamka drzwi przystankowych.',
  misalignment_cd_vs_ld_sill_gap: 'Szczelina w Progu',
  misalignment_cd_vs_ld_sill_gap_actions:
    'Szczelina w progu po lewej i prawej stronie otworu drzwiowego nie jest równa. Jeśli występuje to na wszystkich piętrach, dostosuj pozycję progu drzwi kabinowych.',
  misalignment_cd_vs_ld_sill_gap_description:
    'Drzwi przystankowe nie są równoległe do drzwi kabinowych.',
  mode: 'Tryb',
  monitoring: 'Monitorowanie',
  motor: 'Silnik',
  motorTemperature: 'Temperatura Silnika',
  myBuildingsSubscription: 'Tylko z moich ulubionych',
  name: 'Nazwa',
  networkQuality: 'Jakość Sieci',
  newInstallationControl: 'Nowa Kontrola Instalacji',
  next: 'Następny',
  nextMaintenanceDate: 'Data następnej konserwacji',
  no: 'Nie',
  notAuthorizedToConfirmInviteError:
    'Nie jesteś uprawniony do potwierdzenia tego zaproszenia, ponieważ nie jest przeznaczone dla Twojego zalogowanego użytkownika.',
  noBoxLinked:
    'Winda nie jest jeszcze połączona ze skrzynką ELEVATORSENSE. Aby korzystać ze wszystkich funkcji aplikacji, zeskanuj kod QR na skrzynce lub wprowadź ręcznie identyfikator skrzynki.',
  noBuildingsVisible:
    'Na obecnym widoku mapy nie widać żadnych budynków. Spróbuj dostosować mapę, aby zobaczyć więcej budynków.',
  noComponentsFound: 'Nie znaleziono komponentów dla tego piętra',
  noFavorite: 'Nie jest ulubiony',
  noFavoritesChoosen:
    'Nie wybrałeś jeszcze żadnych ulubionych budynków. Przejdź do swojego budynku i kliknij ikonę gwiazdki, aby dodać go do ulubionych budynków.',
  noFloorInfoAvailable: 'Brak dostępnych informacji o piętrach',
  noInstallationControlFound:
    'ESN elektroniki drzwiowej nie odpowiada ESN ostatniego biegu Kontroli Instalacji. Sprawdź, czy jesteś podłączony do właściwej windy.',
  noSubcontractors: 'Nie znaleziono podwykonawców.',
  noSubcontractorMessage:
    'Obecnie Twoja firma nie jest połączona z żadnym podwykonawcą. Aby to zrobić, wyślij zaproszenie.',
  noSubscription: 'Brak',
  note: 'Notatka',
  noQRCodeDetected:
    'Nie wykryto jeszcze kodu QR. Przesuń kamerę tak, aby kod QR był wyraźny i w pełni widoczny w polu widzenia.',
  nudgingSpeedDescription:
    'Prędkość drzwi w warunkach niskiej prędkości. Krzywa otwierania/zamykania jest obliczana, aby osiągnąć tę ustawioną prędkość, jeśli tryb jest aktywny.',
  nudgingSpeedNote:
    'Rzeczywista prędkość drzwi jest również związana z przełącznikami DIP prędkości napędu. Ustawienie na prędkość 4 oznacza 100% wartości 3.',
  nudgingSpeedTitle: 'Prędkość Przymilania',
  obsolete: 'Nieaktualne',
  offline: 'Offline',
  offlineConnection:
    'Jesteś obecnie offline, ale możesz używać Narzędzia Programowania.',
  ok: 'Regularna Konserwacja',
  online: 'Online',
  openHoldForceDescription: 'Siła stosowana w pozycji otwarcia.',
  openHoldForceNote:
    'Siła ta powinna wynosić od 50N do 60N. Zbyt niska siła może powodować niechciane zamknięcia i otwarcia. Zbyt wysoka siła końcowa otwarcia nagrzewa silnik niepotrzebnie i może prowadzić do pracy w trybie redukcji.',
  openHoldForceTitle: 'Siła Utrzymania Otwarcia',
  openSpeedDescription:
    'Maksymalna prędkość drzwi w kierunku otwierania. Krzywa otwierania jest obliczana, aby osiągnąć tę ustawioną prędkość, jeśli to możliwe.',
  openSpeedNote:
    'Rzeczywista prędkość drzwi jest również związana z przełącznikami DIP prędkości napędu. Ustawienie na prędkość 4 oznacza 100% wartości 1.',
  openSpeedTitle: 'Prędkość Otwierania',
  otherReason: 'Inny powód',
  overdue: 'Przeterminowane',
  parameter: 'Parametr',
  partInformation: 'Informacje o Części',
  partIsStillOk: 'Część jest nadal OK',
  partNumber: 'Numer Części',
  partName: 'Nazwa Części',
  password: 'Hasło',
  passwordResetSent:
    'Wysłaliśmy Ci e-mail z instrukcjami dotyczącymi resetowania hasła. Sprawdź swoją skrzynkę odbiorczą.',
  pendingInvites: 'Oczekujące Zaproszenia',
  pendingUserInvites: 'Oczekujące Zaproszenia Użytkowników',
  pendingSubcontractorInvites: 'Oczekujące Zaproszenia Podwykonawców',
  planningAndAlerts: 'Planowanie i alerty',
  position: 'Pozycja',
  positionOutDescription:
    'Wprowadzając ten parametr, przekaźnik pozycji (związany z FPC) może być dostosowany do przełączania się w dowolnej pozycji drzwi. Standardowe ustawienie to 0 (nieaktywne).',
  positionOutNote:
    'W przypadku ustawienia tego parametru na inną wartość, przekaźnik przełącza się po osiągnięciu pozycji. Funkcja ta jest używana głównie w szybach z kabinami przedsuwnymi.',
  positionOutTitle: 'Pozycja Wysunięcia',
  postpone: 'Przełóż',
  powerReductionFactor: 'Współczynnik Redukcji Mocy',
  predicted: 'Przewidywany',
  prev: 'Poprzedni',
  preventiveMaintenance: 'Konserwacja Zapobiegawcza',
  preventiveMaintenanceDescription:
    'Wymień części zamienne drzwi windy zanim się zepsują.',
  preventiveMaintenanceUnavailable:
    'Wprowadzone dane są kompletne! ElevatorSense teraz obliczy harmonogram konserwacji w oparciu o użytkowanie Twojej windy. Proces ten może potrwać kilka minut.',
  priority: 'Priorytet',
  product: 'Produkt',
  programmingDisabledHint:
    'Programowanie elektroniki jest obecnie wyłączone przez sprzęt. Upewnij się, że tryb serwisowy napędu jest włączony, aby umożliwić programowanie urządzenia.',
  programmingErrorConnection: (
    <>
      <p>Napotkaliśmy problemy z połączeniem z skrzynką ELEVATORSENSE.</p>
      <p>
        Upewnij się, że wyłączono "połączenie danych mobilnych" i upewnij się,
        że jesteś połączony tylko z siecią Wi-Fi skrzynki ELEVATORSENSE.
      </p>
      <p>
        Następnie naciśnij przycisk <b>kontynuuj</b> poniżej.
      </p>
    </>
  ),
  programmingErrorNoPopup:
    'Nie udało się otworzyć okna. Upewnij się, że wyskakujące okna nie są blokowane przez przeglądarkę.',
  programmingErrorRequest:
    'Żądanie nie powiodło się. Upewnij się, że Twoje urządzenie jest podłączone do sieci Wi-Fi skrzynki ELEVATORSENSE.',
  programmingTool: 'Programowanie Bezprzewodowe',
  programmingToolDescription: 'Łatwa konfiguracja elektroniki drzwiowej.',
  pulley_is_touching_belt: 'Koło Pasowe',
  pulley_is_touching_belt_actions:
    'Dostosuj ustawienie pasa tak, aby pas nie dotykał krawędzi koła pasowego.',
  pulley_is_touching_belt_description: 'Koło pasowe dotyka paska.',
  readyToRunTest: 'Gotowy do przeprowadzenia testu.',
  realtimeMonitoringDescription:
    'Zobacz dane z czujników i wykresy ruchu drzwi w czasie rzeczywistym.',
  realtimeMonitoringTitle: 'Diagnostyka w Czasie Rzeczywistym',
  recommended: 'Zaplanuj Sprawdzenie Wymiany',
  recommendedActions: 'Zalecane Działania',
  recommendedDateOfExchange: 'Zalecana Data Sprawdzenia Wymiany',
  reload: 'Przeładuj',
  remove: 'Usuń',
  reopenTimeDescription:
    'Parametr Ponownego Otwarcia definiuje czas oczekiwania po automatycznym ruchu ponownego otwarcia w pozycji końcowej otwarcia przed ponownym zamknięciem drzwi.',
  reopenTimeNote:
    'Funkcja ta jest używana w przypadku procedury ponownego otwierania wyzwalanej przez elektronikę drzwiową, a nie przez główny sterownik windy.',
  reopenTimeParameter: 'Parametr Czasu Ponownego Otwarcia',
  reopenTimeTitle: 'Czas Ponownego Otwarcia',
  repeatFloor: 'Proszę powtórzyć proces kontroli instalacji dla tego piętra!',
  repeatWholeRun: (
    <>
      Proszę powtórzyć <b>cały</b> proces kontroli instalacji!
    </>
  ),
  replaced: 'Zastąpiono',
  replacementWasPostponed: 'Wymiana została przełożona na:',
  requestNewLink: 'Poproś o nowy link',
  resetPassword: 'Zresetuj hasło',
  resetPasswordText: 'Wprowadź nowe hasło.',
  revoke: 'Cofnij',
  right: 'Prawo',
  rollerBumper: 'Rolki Zderzaka',
  rollerSynchronisationRope: 'Rolki Sznura Synchronizacyjnego',
  runs: 'Biegi',
  save: 'Zapisz',
  scan: 'Skanuj',
  scanned: 'Zeskanowano',
  scanAgain: 'Skanuj ponownie',
  searchPlaces: 'Wyszukaj miejsca',
  select: 'Wybierz',
  selectCameraMode: 'Wybierz tryb kamery',
  selectClearOpening: 'Wybierz wolny otwór',
  selectDoorModel: 'Wybierz Model Drzwi',
  selectDoorType: 'Wybierz Typ Drzwi',
  selectModifiedFloors:
    'Wybierz piętra, na których dokonano zmian od ostatniego biegu testowego. Piętra, na których wystąpiły błędy podczas ostatniego biegu, są zaznaczone wstępnie.',
  selectPartsHint:
    'Sprawdź, który element jest zainstalowany i kliknij na odpowiedni obraz.',
  send: 'Wyślij',
  sendResults: 'Wyślij Wyniki',
  sendTo: 'Wyślij wyniki na następujące adresy e-mail:',
  sensorData: 'Dane Czujników',
  serviceDriveSwitch: 'Przełącznik Napędu Serwisowego',
  settings: 'Ustawienia',
  share: 'Udostępnij',
  shareBody: (link: string) => `Zobacz wynik testu:\n${link}`,
  shareResults: 'Udostępnij Wyniki',
  shareSubject: 'Wynik ELEVATORSENSE',
  shoe: 'But',
  showPassedTests: 'Pokaż zdane testy ...',
  showTerms: 'Pokaż warunki użytkowania',
  signUp: 'Zarejestruj się',
  skid: 'Poślizg',
  slideingshoe_failure: 'Awaria Ślizgacza',
  slideingshoes_usage: 'Użycie Ślizgaczy',
  somethingWentWrong: 'Coś poszło nie tak. Spróbuj ponownie.',
  sortBy: 'Sortuj według',
  sortByDate: 'Data',
  sortByFloor: 'Numer piętra',
  sparePartDoesNotFit: 'Część zamienna nie pasuje',
  sparePartNotAvailable: 'Część zamienna niedostępna',
  speed: 'Prędkość',
  start: 'Rozpocznij',
  startNewTestRun: 'Nowy Bieg Testowy',
  started: 'Uruchomiono',
  starting_floor: 'Piętro Początkowe',
  status: 'Status',
  subcontractorNotFound: 'Nie znaleziono podwykonawcy',
  subcontractors: 'Podwykonawcy',
  subcontractorManagement: 'Zarządzanie Podwykonawcami',
  subscriptionHint:
    'Otrzymasz powiadomienia e-mail tylko wtedy, gdy zakupisz odpowiednie produkty dla swojej Skrzynki ElevatorSense.',
  subscriptionText: 'Wybierz powiadomienia e-mail, które chcesz otrzymywać.',
  subscriptions: 'Powiadomienia',
  summarySubscription: 'Raport Dzienny',
  summarySubscriptionExplanation: (
    <>
      <b>Raport dzienny</b> zawiera e-mail z podsumowaniem wszystkich wybranych
      budynków i wind z oznaczeniem liczby błędów.
    </>
  ),
  temperature: 'Temperatura',
  termsOfUse: {
    general: {
      title: 'GENERAL TERMS AND CONDITIONS OF USE OF ELEVATORSENSE APPLICATION',
      sections: [
        {
          title: 'I. Informacje ogólne',
          content: [
            <>
              Wittur GmbH (dalej <strong>"WITTUR"</strong>,{' '}
              <strong>"My"</strong>) oferuje użytkownikom (
              <strong>"Użytkownik"</strong>) – samodzielnie lub za pośrednictwem
              Podmiotów Powiązanych, zdefiniowanych poniżej – rozwiązanie
              składające się ze sprzętu zbierającego dane z dźwigów osobowych i
              towarowych w budynkach lub ich częściach (
              <strong>"ElevatorSense Box"</strong>) oraz z mobilnej i webowej
              aplikacji ElevatorSense (dalej <strong>"Mobile App"</strong>,{' '}
              <strong>"Web App"</strong> oraz łącznie <strong>"App"</strong>).
              (Zarówno ElevatorSense Box, jak i App określane są dalej łącznie
              jako <strong>"ElevatorSense Solution"</strong>).
            </>,
            <>
              Świadczenie ElevatorSense Box jest regulowane odrębną umową
              pomiędzy Użytkownikiem a WITTUR (
              <strong>"Umowa dotycząca Box"</strong>).
            </>,
            <>
              App może być również oferowana przez Podmioty Powiązane WITTUR,
              przy czym WITTUR pozostaje jedyną stroną umowy z Użytkownikiem
              (jak zdefiniowano poniżej) w takim przypadku w odniesieniu do
              świadczenia App. <strong>"Podmiot Powiązany"</strong> oznacza
              wszystkie spółki będące w większościowej własności lub
              zaangażowane w większościowy udział kapitałowy lub udział w
              akcjonariacie między sobą, spółki zależne i dominujące, spółki
              znajdujące się pod jednolitym zarządem jednej spółki
              kontrolującej, oraz spółki posiadające udziały lub akcje we
              wzajemnych relacjach.
            </>,
            'WITTUR udostępnia App wyłącznie przedsiębiorcom. Przedsiębiorcą jest osoba fizyczna lub prawna lub spółka posiadająca zdolność prawną, która zawierając czynność prawną działa w ramach wykonywania swojej działalności handlowej, gospodarczej lub zawodowej. Konsumenci nie mogą korzystać z App.',
            <>
              Niniejsze warunki regulują w całości stosunki prawne pomiędzy
              WITTUR a Użytkownikiem w zakresie App (
              <strong>"Warunki Użytkowania"</strong>) i obejmują{' '}
              <i>między innymi</i> dostęp i korzystanie z App, z zastrzeżeniem
              dodatkowych lub odmiennych warunków określonych w{' '}
              <a href="#appendix_1">Aneksie 1</a>. Warunki Użytkowania stają się
              wiążące po zakończeniu rejestracji przez Użytkownika i w tym
              momencie zostaje zawarta umowa o świadczenie usług z WITTUR (
              <strong>"Umowa"</strong>). Warunki Użytkowania są przechowywane
              przez WITTUR i są dostępne dla Użytkownika w dowolnym momencie w
              ramach App.
            </>,
            'Jako przedsiębiorca, Użytkownik nie ma prawa do odstąpienia od umowy.',
          ],
        },
        {
          title: 'II. Zakres',
          content: [
            <>
              Aplikacja (App) jest aplikacją mobilną i internetową, która
              umożliwia korzystanie z usług opisanych w Umowie Box (
              <strong>"Usługi"</strong>). Aplikacja zapewnia również informacje,
              wizualizacje, powiadomienia, instrukcje i oceny dotyczące
              właściwych działań, które należy podjąć (
              <strong>"Zalecenia"</strong>). Razem z Zaleceniami w Aplikacji
              Użytkownik otrzymuje hiperłącze do sklepu internetowego WITTUR z
              komponentami Rozwiązania ElevatorSense.
            </>,
            <>
              Korzystanie z Aplikacji podlega wyłącznie niniejszym Warunkom
              Użytkowania, jak również informacjom zawartym w Aplikacji.
              Odstępujące lub sprzeczne warunki nie stają się częścią umowy z
              WITTUR, o ile WITTUR nie wyrazi na to wyraźnej pisemnej zgody.
              Niezależnie od powyższego zdania, Użytkownik jest świadomy, że
              oprócz niniejszych Warunków Użytkowania może być zobowiązany do
              przestrzegania dodatkowych warunków użytkowania operatora
              odpowiedniego sklepu z aplikacjami (np. Google w przypadku Google
              Play Store lub Apple w przypadku Apple App Store), jeśli
              Użytkownik korzysta z Aplikacji Mobilnej pobranej z danego sklepu,
              i że WITTUR nie ma żadnego wpływu na takie dodatkowe warunki i w
              związku z tym nie ponosi za nie żadnej odpowiedzialności.
            </>,
            'Pobranie Aplikacji Mobilnej wymaga odpowiednich urządzeń końcowych i dostępu do Internetu, co może wiązać się z kosztami połączenia z Google Play Store / Apple App Store.',
            <>
              Strony przyjmują do wiadomości i zgadzają się, że świadczenie
              Usług w lokalnych jurysdykcjach określonych w{' '}
              <a href="#appendix_1">Aneksie 1</a> wymaga dodania lub zastąpienia
              niniejszych Warunków Użytkowania dodatkowymi lub odmiennymi
              warunkami lub wymaganiami. W związku z tym stosunek umowny między
              Stronami podlega dodatkowym lub odmiennym warunkom określonym w{' '}
              <a href="#appendix_1">Aneksie 1</a>, jeżeli ma to zastosowanie
              (dalej: <strong>"Warunki Lokalnych Jurysdykcji"</strong>). W
              przypadku konfliktu między niniejszymi Warunkami Użytkowania a
              Warunkami Lokalnych Jurysdykcji, pierwszeństwo mają Warunki
              Lokalnych Jurysdykcji.
            </>,
          ],
        },
        {
          title: 'III. Wynagrodzenie',
          content: [
            'Korzystanie z podstawowych funkcjonalności Aplikacji jest bezpłatne. Jednak Użytkownik jest świadomy, że Aplikacja jest użyteczna wyłącznie w połączeniu z ElevatorSense Box, dla której istnieją różne modele zakupu lub wynajmu, podlegające Umowie dotyczącej Box.',
            <>
              Mogą pojawić się dodatkowe opłaty za specjalne funkcje dostępne w
              Aplikacji, które Użytkownik może nabyć (<strong>"Funkcje"</strong>
              ). Dostępne dla Użytkownika Funkcje mogą różnić się w zależności
              od kraju, w którym Aplikacja jest używana.
            </>,
          ],
        },
        {
          title: 'IV. Udostępnienie Aplikacji; Utrzymanie',
          content: [
            <>
              WITTUR zapewni rozsądny dostęp i korzystanie z Aplikacji. W tym
              celu WITTUR udostępni Aplikację zarejestrowanym, stałym i/lub
              niezależnym pracownikom Użytkownika (
              <strong>"Upoważniony Personel"</strong>).
            </>,
            'WITTUR może, według własnego, wyłącznego i swobodnego uznania, ograniczyć dostęp do Aplikacji w całości lub części, tymczasowo lub na stałe, z powodu prac konserwacyjnych, problemów z pojemnością i innych zdarzeń poza jej kontrolą. WITTUR zazwyczaj przeprowadza prace konserwacyjne poza normalnymi godzinami pracy, chyba że natychmiastowe zdarzenie wymaga, według wyłącznego i swobodnego uznania WITTUR, konserwacji w normalnych godzinach pracy. Każda część Aplikacji może zostać zmieniona w dowolnym momencie i bez powiadomienia, według wyłącznego i swobodnego uznania WITTUR.',
            'WITTUR zapewnia dostęp do Web App w środowisku hostowanym, co umożliwia Użytkownikowi korzystanie z Aplikacji przez Internet bez konieczności instalowania i obsługi oprogramowania na własnej infrastrukturze IT.',
            'WITTUR dołoży rozsądnych starań, aby utrzymać dostępność Web App dla Użytkownika w punkcie styku między publicznym Internetem a siecią serwerów hostingowych WITTUR. Web App jest uznawana za dostępną, jeśli jest używalna w punkcie styku między publicznym Internetem a siecią serwerów hostingowych WITTUR.',
            <>
              WITTUR udostępnia aktualizacje Aplikacji zgodnie z uznanym stanem
              techniki, bez dodatkowego wynagrodzenia (
              <strong>"Aktualizacje"</strong>). Aktualizacje w rozumieniu
              niniejszych Warunków Użytkowania charakteryzują się poprawkami
              błędów lub drobnymi innymi ulepszeniami, bez istotnych nowych
              funkcjonalności. Aktualizacje nie obejmują dostarczenia lub
              udostępnienia dodatkowych, nowo oferowanych funkcjonalności lub
              dodatkowych komponentów.
            </>,
            <>
              Użytkownik musi zainstalować Aktualizacje dla Aplikacji Mobilnej
              (tzn. po stronie klienta) natychmiast po ich udostępnieniu.
              Aktualizacje umożliwiają Aplikacji Mobilnej odbieranie aktualnych
              informacji i zapewniają jej prawidłowe działanie. W przeciwnym
              razie WITTUR nie może zagwarantować prawidłowego działania
              Aplikacji Mobilnej ani aktualności dostarczanych informacji.
              Jeżeli Użytkownik nie zainstaluje Aktualizacji, a mimo to nadal
              będzie korzystał z przestarzałej wersji Aplikacji Mobilnej,
              Użytkownik tym samym zrzeka się wszelkich roszczeń z tytułu wad
              oraz roszczeń o odszkodowanie, do których mógłby być uprawniony.
            </>,
          ],
        },
        {
          title: 'V. Konto Użytkownika',
          content: [
            <>
              Dostęp do Aplikacji może zostać przyznany Użytkownikowi oraz jego
              Upoważnionemu Personelowi dopiero po dokonaniu rejestracji i
              utworzeniu konta użytkownika w Aplikacji (
              <strong>"Konto Użytkownika"</strong>).
            </>,
            'Użytkownik musi zgłosić WITTUR swój Upoważniony Personel.',
            'Użytkownik ponosi wyłączną i całkowitą odpowiedzialność za zarządzanie swoim Upoważnionym Personelem, np. przydzielanie ról i praw w Aplikacji. W szczególności Użytkownik jest zobowiązany zakończyć dostęp do Aplikacji, gdy dane osoby przestaną pracować dla Użytkownika.',
            <>
              Użytkownik jest zobowiązany do zachowania w tajemnicy danych
              dostępu do swojego Konta Użytkownika oraz do ochrony ich przed
              dostępem osób trzecich. Jeśli Użytkownik dowie się o utracie lub
              niewłaściwym użyciu swoich danych dostępu lub podejrzewa
              niewłaściwe wykorzystanie swojego konta użytkownika, musi
              niezwłocznie powiadomić o tym WITTUR i zmienić hasło, korzystając
              z funkcjonalności dostępnej w Aplikacji. Ujawnianie danych
              dostępowych osobom trzecim jest zabronione. Użytkownik nie może
              korzystać z danych dostępowych innej osoby, aby uzyskać dostęp do
              Aplikacji. Użytkownik ponosi wyłączną i całkowitą odpowiedzialność
              za działania każdej osoby uzyskującej dostęp do Aplikacji przy
              użyciu danych dostępowych Użytkownika, w tym Upoważnionego
              Personelu, nawet jeśli te działania nie zostały faktycznie
              autoryzowane przez Użytkownika.
            </>,
          ],
        },
        {
          title: 'VI. Obowiązki Użytkownika',
          content: [
            <>
              Użytkownik ponosi wyłączną i całkowitą odpowiedzialność za
              wszelkie treści, które przesyła, publikuje lub w inny sposób
              udostępnia publicznie za pośrednictwem Aplikacji. Korzystając z
              konta w Aplikacji, Użytkownik oświadcza i gwarantuje wobec WITTUR,
              że informacje, które przekazuje WITTUR (
              <strong>"Treści Użytkownika"</strong>), są prawdziwe, dokładne i
              kompletne. Ponadto Użytkownik gwarantuje, że Treści Użytkownika
              pozostaną prawdziwe, dokładne i kompletne. WITTUR nie monitoruje
              Treści Użytkownika pod kątem ich kompletności, dokładności,
              legalności, dostępności, jakości ani przydatności do
              jakiegokolwiek celu.
            </>,
            <>
              Usługi mogą być wykonywane wyłącznie, gdy ElevatorSense Box jest
              podłączony do Internetu. Użytkownik uznaje, że jego wyłączną i
              całkowitą odpowiedzialnością jest zapewnienie technicznej
              dostępności ElevatorSense Box. Niniejszym Użytkownik zrzeka się
              wszelkich praw i wynikających z nich roszczeń, do których mógłby
              być uprawniony z powodu braku łączności ElevatorSense Box.
            </>,
            'W celu wykonania Usług Użytkownik jest zobowiązany ściśle przestrzegać instrukcji instalacji i eksploatacji podanych w Aplikacji.',
            <>
              Jeśli WITTUR udostępnia aktualizacje i/lub ulepszenia (upgrades)
              do pobrania dla ElevatorSense Box, Użytkownik jest zobowiązany je
              zainstalować, aby zapewnić prawidłową interakcję między Aplikacją
              a ElevatorSense Box. W przeciwnym razie WITTUR nie może zapewnić,
              że Usługi będą realizowane prawidłowo. Jeżeli Użytkownik nie
              zainstaluje dostarczonej aktualizacji i/lub ulepszeń, a mimo to
              nadal będzie korzystał ze starszej wersji ElevatorSense Box,
              Użytkownik tym samym zrzeka się wszelkich roszczeń dotyczących wad
              oraz roszczeń o odszkodowanie, do których mógłby być uprawniony.
            </>,
            'Użytkownikowi zabrania się podejmowania jakichkolwiek działań w Aplikacji lub w związku z nią, które naruszają obowiązujące prawo lub prawa osób trzecich.',
            {
              text: 'Ponadto, Użytkownikowi zabrania się wykonywania następujących czynności, niezależnie od naruszenia prawa:',
              list: [
                'rozpowszechnianie wirusów, trojanów i innych szkodliwych plików;',
                'wysyłanie niechcianej poczty (junk mail) lub spamu oraz łańcuszków (chain letters);',
                'rozpowszechnianie treści lub komunikacji obraźliwych, o charakterze seksualnym, obscenicznych lub oszczerczych, a także takich treści lub komunikacji, które mogą promować lub wspierać rasizm, fanatyzm, nienawiść, przemoc fizyczną lub działania nielegalne (w każdym przypadku wyraźnie lub domyślnie);',
                'żądanie od innych użytkowników ujawnienia haseł lub danych osobowych w celach komercyjnych lub nielegalnych;',
                'rozpowszechnianie i/lub publiczne odtwarzanie treści dostępnych w Aplikacji.',
              ],
            },
            'WITTUR zastrzega sobie prawo do zawieszenia Użytkownikowi dostępu do Aplikacji w dowolnym momencie, jeśli zostaną naruszone niniejsze Warunki Użytkowania, obowiązujące prawo lub prawa osób trzecich, lub jeśli WITTUR ma uzasadnione podstawy sądzić, że mogą one zostać naruszone.',
          ],
        },
        {
          title: 'VII. Prawa Własności Intelektualnej',
          content: [
            <>
              WITTUR udziela Użytkownikowi odwołalnego, niewyłącznego,
              nieprzenoszalnego, niepodlegającego sublicencjonowaniu prawa do
              korzystania z Aplikacji, ograniczonego w czasie do okresu
              obowiązywania Umowy, zgodnie z niniejszymi Warunkami Użytkowania (
              <strong>"Licencja"</strong>). Właścicielem praw własności
              intelektualnej do Aplikacji jest WITTUR lub jej partnerzy
              biznesowi lub inni licencjodawcy, którzy udostępnili WITTUR
              odpowiednie treści i prawa własności intelektualnej na podstawie
              licencji. Aplikacja i treści udostępnione w Aplikacji nie mogą być
              zatem zmieniane, rozszerzane, edytowane, kopiowane i/lub w inny
              sposób dystrybuowane przez Użytkownika.
            </>,
            <>
              Rekomendacje generowane przez Aplikację mogą być wykorzystywane
              przez Użytkownika w celu wspierania własnych usług wobec klientów
              Użytkownika. WITTUR nie przejmuje żadnej odpowiedzialności ani
              zobowiązań w odniesieniu do tych usług ani działań Użytkownika lub
              jego klientów wynikających z nich.
            </>,
            <>
              O ile nie postanowiono inaczej w niniejszych Warunkach
              Użytkowania, Użytkownik zobowiązuje się nie powielać, nie
              rozpowszechniać, nie modyfikować ani nie tworzyć dzieł pochodnych
              Aplikacji lub jakichkolwiek jej komponentów, a także nie dokonywać
              inżynierii wstecznej ani dekompilacji Aplikacji, chyba że jest to
              dozwolone przez bezwzględnie obowiązujące przepisy prawa.
            </>,
            <>
              Użytkownik przyjmuje do wiadomości i zgadza się, że WITTUR jest
              upoważniona do wykorzystywania danych przetwarzanych za
              pośrednictwem Aplikacji w zanonimizowanej formie (w rozumieniu
              obowiązujących przepisów o ochronie danych osobowych) do dowolnych
              celów biznesowych WITTUR, w szczególności do ich powielania i
              udostępniania osobom trzecim. WITTUR nie będzie deanonymizować
              danych i jest zobowiązana podjąć wszelkie odpowiednie środki
              zapobiegające deanonymizacji danych. Jeśli zostanie przyznany
              dostęp do danych osobom trzecim, WITTUR zobowiąże te osoby trzecie
              do nieprzeprowadzania deanonymizacji danych oraz do podjęcia
              wszelkich odpowiednich środków w celu zapobieżenia deanonymizacji.
              W zakresie wymaganym, Użytkownik udziela WITTUR nieodwołalnej,
              ogólnoświatowej, niewyłącznej, wolnej od opłat licencyjnych,
              podlegającej sublicencjonowaniu licencji na wykorzystanie tych
              danych. Cele biznesowe obejmują między innymi rozwój, wytwarzanie,
              ulepszanie oraz/lub marketing produktów i usług.
            </>,
          ],
        },
        {
          title: 'VIII. Okres obowiązywania i rozwiązanie',
          content: [
            <>
              Umowa ma nieograniczony czas trwania, a Użytkownik ma prawo
              wypowiedzieć Umowę w dowolnym momencie bez podania przyczyny.
              Użytkownik przyjął do wiadomości i jest świadomy, że (i)
              funkcjonalność Aplikacji zostanie ograniczona lub zawieszona w
              momencie wygaśnięcia okresu subskrypcji i/lub praw użytkowania
              ElevatorSense Box wynikających z Umowy dotyczącej Box, oraz (ii)
              wypowiedzenie Umowy przez Użytkownika bez podania przyczyny nie
              będzie miało wpływu na jego zobowiązania, które mogą nadal wynikać
              z Umowy dotyczącej Box.
            </>,
            <>
              O ile nie uzgodniono inaczej z Użytkownikiem, licencje na Funkcje
              zakupione przez Użytkownika są ważne do końca odpowiedniego okresu
              obowiązywania danej Funkcji. Jeśli Użytkownik nie wypowie licencji
              na Funkcję na trzy (3) miesiące przed końcem okresu, okres ten
              zostanie automatycznie przedłużony o dwanaście (12) miesięcy, a
              Użytkownik będzie zobowiązany do zapłaty dodatkowych opłat
              uzgodnionych na ten okres. Prawo do wypowiedzenia licencji na
              Funkcję ze skutkiem natychmiastowym z ważnej przyczyny pozostaje
              nienaruszone. Postanowienia sekcji VIII.1 stosuje się odpowiednio
              do Funkcji.
            </>,
            <>
              Po rozwiązaniu Umowy Użytkownik zobowiązany jest (i) natychmiast
              zaprzestać korzystania z Aplikacji i przyjmuje do wiadomości, że
              WITTUR może zablokować dostęp Użytkownika i jego Upoważnionego
              Personelu do Aplikacji oraz (ii) niezwłocznie, nie później niż w
              ciągu 5 (pięciu) dni, usunąć i zniszczyć wszelkie pobrane treści i
              materiały drukowane. Użytkownik nie posiada żadnych praw, tytułów
              ani udziałów (ani praw autorskich, znaków towarowych czy innych
              praw własności intelektualnej) do Aplikacji ani jakichkolwiek
              treści, informacji, materiałów, aplikacji czy innych powiązanych z
              nimi funkcjonalności.
            </>,
          ],
        },
        {
          title: 'IX. Prawa w Przypadku Wad',
          content: [
            <>
              Aplikacja będzie dostarczana i utrzymywana w stanie odpowiednim do
              umownego użytkowania. Obowiązek konserwacji nie obejmuje
              dostosowywania funkcjonalności samej Aplikacji do zmienionych
              warunków operacyjnych oraz rozwoju technicznego i funkcjonalnego,
              które nie są powiązane z ElevatorSense Box, dostosowania do
              zakresu funkcji konkurencyjnych produktów ani zapewnienia
              kompatybilności z nowymi wersjami przeglądarek.
            </>,
            {
              text: (
                <>
                  WITTUR zapewnia, że Aplikacja jest wolna od praw osób
                  trzecich, w szczególności praw własności intelektualnej, które
                  ograniczałyby lub wykluczały jej użytkowanie zgodnie z
                  niniejszymi Warunkami Użytkowania. W przypadku jakichkolwiek
                  zarzutów, że korzystanie z Aplikacji, zgodnie z niniejszymi
                  Warunkami Użytkowania, narusza lub łamie patent, prawa
                  autorskie, znak towarowy lub inne prawa własności
                  intelektualnej osoby trzeciej (<strong>"Naruszenie"</strong>)
                </>
              ),
              list: [
                <>
                  WITTUR ma prawo albo zmodyfikować Aplikację w taki sposób, aby
                  Naruszenie nie miało już zastosowania, albo uzyskać
                  upoważnienie do użytkowania Aplikacji zgodnie z Warunkami
                  Użytkowania, bez ograniczeń i bez dodatkowych kosztów dla
                  Użytkownika;
                </>,
                <>
                  Użytkownik zobowiązuje się do niezwłocznego powiadomienia
                  WITTUR w formie tekstowej (np. e-mailem) i dostarczenia
                  wszystkich informacji w związku z Naruszeniem; oraz
                </>,
                <>
                  Użytkownik zobowiązuje się do współpracy i udzielenia WITTUR
                  pomocy w zaprzestaniu Naruszenia, w zakresie, jakiego można od
                  niego rozsądnie oczekiwać.
                </>,
              ],
            },
            'Prawo do dochodzenia odszkodowania podlega ograniczeniom odpowiedzialności zgodnie z następną Sekcją X..',
          ],
        },
        {
          title: 'X. Ograniczenie Odpowiedzialności',
          content: [
            'WITTUR ponosi nieograniczoną odpowiedzialność za szkody spowodowane umyślnym działaniem lub rażącym niedbalstwem WITTUR lub jej pomocników, za szkody spowodowane uszkodzeniem ciała, zdrowia lub życia, a także w zakresie obowiązujących przepisów o odpowiedzialności za produkt.',
            <>
              Za szkody i koszty spowodowane zwykłym niedbalstwem WITTUR
              odpowiada wyłącznie za typowo przewidywalne szkody, jeśli szkody i
              koszty te wynikają z naruszenia przez WITTUR istotnych zobowiązań
              umownych. Zobowiązania umowne są istotne, jeśli ich spełnienie
              jest wymagane do osiągnięcia celu Umowy, a Użytkownik może na ich
              spełnienie polegać.
            </>,
            'We wszystkich pozostałych przypadkach WITTUR nie ponosi odpowiedzialności.',
            <>
              Dla uniknięcia wątpliwości, WITTUR w żadnym wypadku nie ponosi
              odpowiedzialności za szkody lub straty w zakresie, w jakim
              powstaną one w wyniku nieprzestrzegania przez Użytkownika zaleceń
              lub uwag udostępnionych przez lub zawartych w Aplikacji.
            </>,
          ],
        },
        {
          title: 'XI. Ograniczenia Odpowiedzialności za Dostarczane Treści',
          content: [
            <>
              WITTUR nie ponosi odpowiedzialności za jakiekolwiek informacje
              udostępnione w ramach Usług ani za jakiekolwiek Rekomendacje
              udostępnione w Aplikacji (<strong>"Dostarczane Treści"</strong>).
              Jak wynika z Sekcji VII.2, WITTUR nie ponosi odpowiedzialności za
              wykorzystanie Dostarczanych Treści przez lub dla własnych klientów
              Użytkownika.
            </>,
            <>
              Podczas udostępniania informacji w Usługach dane mogą nie być
              dokładne, gdyż dane generowane przez ElevatorSense Box są zależne
              od warunków zewnętrznych.
            </>,
            <>
              Podczas udostępniania Rekomendacji, Aplikacja przedstawia pewne
              prawdopodobieństwa dla określonego wyniku i rekomenduje działania
              odpowiednio. Jednak Aplikacja nie dokonuje żadnej ostatecznej
              oceny. Dokonanie właściwej oceny, w tym zakup produktów
              ElevatorSense w sklepie internetowym WITTUR jako zamienników,
              pozostaje wyłączną i całkowitą odpowiedzialnością Użytkownika.
            </>,
            {
              text: 'Użytkownik powinien mieć na uwadze następujące ograniczenia, które mają zastosowanie do Dostarczanych Treści generowanych przez tę Aplikację:',
              list: [
                <>
                  Dostarczane Treści są generowane przy użyciu bardzo złożonych
                  algorytmów. Oczywiste jest jednak, że żaden algorytm nie może
                  odzwierciedlić każdej pojedynczej cechy indywidualnego
                  zdarzenia. Aplikacja zatem zapewnia stale ulepszaną
                  aproksymację w celu wspierania spersonalizowanego podejmowania
                  decyzji.
                </>,
                <>
                  Nowe i zaktualizowane dane, które stanowią podstawę
                  Dostarczanych Treści, są stale wprowadzane do algorytmu.
                  WITTUR gromadzi te dane według najlepszej wiedzy i z
                  zachowaniem profesjonalnej staranności. Jednak nie można
                  zagwarantować, że dane są poprawne, kompletne i aktualne.
                  Błędy w zestawie danych mogą prowadzić do niepewnych
                  Dostarczanych Treści.
                </>,
              ],
            },
          ],
        },
        {
          title: 'XII. Ochrona Danych',
          content: [
            <>
              Ponieważ ochrona danych ma najwyższy priorytet w WITTUR, WITTUR
              ściśle przestrzega obowiązujących przepisów o ochronie danych
              osobowych podczas przetwarzania (np. gromadzenia, wykorzystywania,
              ujawniania itp.) danych osobowych. Jeśli i w zakresie, w jakim nie
              poinformowano inaczej o specyficznym przetwarzaniu danych
              osobowych przez WITTUR, informacje na temat takiego przetwarzania
              są zawarte w obowiązującej polityce prywatności, która jest
              dostępna w Aplikacji.
            </>,
            'Użytkownik ma obowiązek przestrzegać obowiązujących przepisów o ochronie danych, gdy udostępnia WITTUR dane osobowe, np. za pośrednictwem Aplikacji.',
          ],
        },
        {
          title: 'XIII. Zmiany / Przeniesienie Umowy',
          content: [
            <>
              WITTUR jest uprawniona do zmiany niniejszych Warunków Użytkowania
              w dowolnym momencie. Użytkownik zostanie powiadomiony o takich
              zmianach co najmniej sześćdziesiąt (60) dni kalendarzowych przed
              planowanym wprowadzeniem zmian. Jeżeli Użytkownik nie sprzeciwi
              się w ciągu trzydziestu (30) dni kalendarzowych od otrzymania
              powiadomienia i będzie nadal korzystał z Aplikacji po upływie
              okresu sprzeciwu, uznaje się, że zmiany zostały prawidłowo
              uzgodnione z upływem tego okresu. W takim powiadomieniu Użytkownik
              zostanie poinformowany o prawie do sprzeciwu i jego
              konsekwencjach.
            </>,
            <>
              WITTUR zastrzega sobie prawo do przeniesienia niniejszej Umowy z
              Użytkownikiem na inną firmę. Użytkownicy zostaną poinformowani o
              takim przeniesieniu i mogą odstąpić od Umowy w terminie dziesięciu
              (10) dni kalendarzowych od momentu otrzymania informacji o
              przeniesieniu.
            </>,
          ],
        },
        {
          title: 'XIV. Postanowienia Końcowe',
          content: [
            <>
              Jeśli jedno lub więcej postanowień niniejszych Warunków
              Użytkowania jest nieważne lub niewykonalne z powodu naruszenia
              obowiązującego prawa lub z innego powodu, pozostałe postanowienia
              zachowują swoją ważność. Nieważne lub niewykonalne postanowienie
              zostanie zastąpione ważnym i wykonalnym postanowieniem, które w
              najbliższym możliwym stopniu odzwierciedla wspólne rozumienie
              stron.
            </>,
            <>
              Prawem właściwym dla ważności, interpretacji i wykonania
              niniejszych Warunków Użytkowania jest prawo niemieckie z
              wyłączeniem jego norm kolizyjnych, a sądy właściwe do
              rozstrzygania wszelkich sporów wynikających z lub związanych z
              niniejszymi Warunkami Użytkowania są sądami w Monachium.
            </>,
          ],
        },
        {
          title: 'XV. Użytkownicy Międzynarodowi',
          content: [
            <>
              Ta Aplikacja jest obsługiwana, monitorowana i aktualizowana przez
              WITTUR GmbH. Jest przeznaczona wyłącznie do użytku w krajach
              wymienionych w<a href="#appendix_1">Aneksie 1</a>. Jeśli
              Użytkownik uzyskuje dostęp do tej Aplikacji i/lub pobiera treści
              spoza tych krajów, to Użytkownik ponosi wyłączną odpowiedzialność
              za zapewnienie, że takie korzystanie jest zgodne z odpowiednimi
              przepisami lokalnego prawa.
            </>,
          ],
        },
      ],
    },
    appendix: {
      title: 'Aneks 1: KRAJ DYSTRYBUCJI I LOKALNE WARUNKI JURYSDYKCYJNE',
      content: [
        {
          heading: 'Dodatkowe lub Odmienne Warunki dla Kraju Dystrybucji',
          details: [
            {
              country: 'Indie',
              content: [
                'Sekcja V. (Konto Użytkownika) zostaje uzupełniona o:',
                <>
                  Użytkownik wyraża zgodę na to, by WITTUR lub jej Podmioty
                  Powiązane gromadziły jego hasło w celu umożliwienia dostępu do
                  Aplikacji i jej użytkowania. Hasło może być przetwarzane przez
                  WITTUR lub jej Podmioty Powiązane zgodnie z Polityką
                  Prywatności.
                </>,
                <>
                  Sekcja X.2. (Ograniczenie Odpowiedzialności) zostaje zmieniona
                  w następujący sposób: Za szkody i koszty spowodowane istotnym
                  naruszeniem przez WITTUR, WITTUR odpowiada jedynie za szkody,
                  które naturalnie wynikają w zwykłym toku takiego naruszenia,
                  lub o których Użytkownik wiedział, w momencie akceptacji
                  Warunków Użytkowania, że prawdopodobnie wynikną z tego
                  naruszenia. (i) Nie obejmuje to szkód powstałych z powodu
                  odległych i pośrednich strat lub szkód wynikających z
                  istotnego naruszenia oraz (ii) jest ograniczone do
                  wynagrodzenia otrzymanego od Użytkownika za ElevatorSense
                  Solution. Użytkownik i WITTUR uznają, że jest to uczciwa,
                  rozsądna i rzeczywista wstępna ocena wszelkich szkód
                  powstałych w wyniku istotnego naruszenia.
                </>,
              ],
            },
            {
              country: 'Wielka Brytania',
              content: [
                <>
                  Sekcja X.2. (Ograniczenie Odpowiedzialności) zostaje zmieniona
                  w następujący sposób:
                  <br /> WITTUR ponosi odpowiedzialność jedynie za typowo
                  przewidywalne szkody i koszty, jeśli zostały one spowodowane
                  istotnym naruszeniem przez WITTUR, którego WITTUR nie usunęła
                  w terminie trzydziestu (30) dni od otrzymania zawiadomienia o
                  naruszeniu od Klienta. Z zastrzeżeniem powyższego, WITTUR
                  wyłącza wszelką inną odpowiedzialność (bezpośrednią lub
                  pośrednią, niezależnie od przyczyny) wynikającą z niniejszych
                  Warunków Użytkowania.
                </>,
              ],
            },
          ],
        },
      ],
    },
  },
  testCompleted: 'Test zakończony.',
  testResults: 'Wyniki Testu',
  time: 'Czas',
  tooHigh: 'Zbyt wysokie',
  tooLoose: 'Zbyt luźne',
  tooLow: 'Zbyt niskie',
  tooTight: 'Zbyt napięte',
  top: 'Góra',
  topToBottom: 'Od Góry do Dołu',
  touching: 'dotyka',
  transformer: 'Transformator',
  truckRoller: 'Rolki Ciężarówki',
  truckRollerInferior: 'Dolne Rolki Ciężarówki',
  truckRollerSuperior: 'Górne Rolki Ciężarówki',
  twisted_cd_panels: 'Wykrzywione Panele CD',
  twisted_ld_panels: 'Wykrzywione Panele',
  unlinkBox: 'Odłącz Skrzynkę',
  unreadNotifications: (n: number) =>
    `${n} nieprzeczytane powiadomienie${n === 1 ? '' : 'a'}`,
  unshare: 'Przestań Udostępniać',
  update: 'Aktualizuj',
  updateRequired: 'Wymagana Aktualizacja',
  updateStaticData: 'Aktualizuj Dane',
  updating: 'Aktualizacja...',
  upToDate: 'Aktualne',
  usage: 'Użycie',
  usedElectronics: 'Użyte Windy',
  validFrom: 'Ważne od',
  validUntil: 'Ważne do',
  vibration: 'Wibracje',
  viewErrorLog: 'Wyświetl Dziennik Błędów',
  waitWhileInitializingTest: 'Poczekaj na inicjalizację testu...',
  waitingForData: 'Oczekiwanie na nowe dane',
  waitingForTestResults: 'Oczekiwanie na wyniki testu...',
  warning: 'Ostrzeżenie',
  warnings: 'Ostrzeżenia',
  warrantyExtensionRequestedOn: (date: string) =>
    `Przedłużenie gwarancji zostało zażądane ${date}`,
  whyNotReplaced: 'Dlaczego komponent nie został wymieniony?',
  wifiScanned: 'Wi-Fi zostało zeskanowane',
  years: 'lata',
  yes: 'Tak',
  zero_position: 'Pozycja Zero',
  zero_position_description: 'Pozycja zero jest nieprawidłowa.',
  zero_position_eco_actions:
    'Procedura ECO+:\n  1) Ustaw S1DIP1 na ON (Tryb Serwisowy).\n  2) Zamknij drzwi ręcznie (pozycja zero).\n  3) Naciśnij przycisk uczenia > 10s (Długie Uczenie).\n  4) Otwórz i zamknij drzwi za pomocą przycisku napędu serwisowego.\n  5) Przytrzymaj przyciski na końcu otwarcia drzwi i końcu zamknięcia drzwi przez > 2s.\n  Wynik: Dioda LED powinna zgasnąć po 2 cyklach.\n  ',
  zero_position_midi_actions:
    'Procedura MIDI/SUPRAV2:\n  Zamknij drzwi ręcznie (pozycja zero).\n  Postępuj zgodnie z instrukcjami na piktogramie.',
  yourBuilding: 'Twój Budynek',
  yourBuildings: 'Twoje Budynki',
};
